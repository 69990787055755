import { combineReducers } from "redux";
import AuthReducer from "./auth/auth.reducer";
import UserReducer from "./users/user.reducer";
import ActionReducer from "./actions/action.reducer";
import TournamentReducer from "./tournaments/tournament.reducer";
import GroupReducer from "./groups/group.reducer";
import MatchReducer from "./matches/match.reducer";
import QuestionReducer from "./questions/questtion.reducer";


const rootReducer = combineReducers({
    auth: AuthReducer,
    users: UserReducer,
    actions: ActionReducer,
    tournaments: TournamentReducer,
    groups: GroupReducer,
    matches: MatchReducer,
    questions: QuestionReducer,
});

const reducer = (state, action) => rootReducer(state, action);

export default reducer;
