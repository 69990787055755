
const urls = {
    login: "login/",
    signup: "users/",
    users_details: "users/details/",
    tournaments: "tournaments/",
    groups: "groups/",
    matches: "matches/",
    questions: "questions/",
    users : "users/",
    forgot_password: "forgotpassword/",
    reset_password: "resetpassword/",
    change_password: "changepassword",

};

export default urls;