import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  CardHeader,
  CardContent,
  TextField,
  Card,
  Grid,
  Button,
  Container,
  Typography,
  InputLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { theme } from '../../utils/theme';
import { login } from '../../redux/auth/auth.actions';
import {
  setSuccess,
  setAccountCreated,
} from '../../redux/actions/action.actions';
import { Block } from '@mui/icons-material';

const InputField = styled(TextField)`
  border-radius: 8px;
  margin-top: 8px;
  color: ${theme.palette.primary.contrastText},
  margin-bottom: 16px;
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &:hover fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-focused fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-error fieldset {
      border-color: #your-error-color; /* Error state */
    }
  }
`;

const Auth = props => {
  //   const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const location = useLocation();
  const success = useSelector(state => state.actions.success);
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (params.has('sessionExpired')) {
      toast.error('Token Expired. Please Login again.', {
        position: 'top-center',
        autoClose: 3000,
      });
    }
    setParams([]);
    // eslint-disable-next-line
  }, [params]);

  const handleSignin = async e => {
    e.preventDefault();
    if (!values.email || !values.password) {
      toast.warning('Email and password are required.', {
        position: 'top-center',
        autoClose: 5000,
      });
      return;
    }

    try {
      dispatch(login(values.email, values.password));
    } catch (error) {
      console.error('Error during signin:', error);
    }
  };
  const handleForgotPassword = () =>{
    navigate('/forgot-password');
  }
  
  const handleToggle = () => {
    setAccountCreated(null);
    navigate('/signup');
  };

  const handleChange = prop => e => {
    setValues({ ...values, [prop]: e.target.value });
  };

  useEffect(() => {
    if (success && location.pathname === '/') {
      navigate('/dashboard');
      dispatch(setSuccess(false));
    }

    // eslint-disable-next-line
  }, [success]);

  return (
    <>
      <Container
        sx={{
          height: '100vh',
          padding: '0!important',
        }}
        maxWidth={'100vw'}
      >
        <Grid container>
          <Grid item xs={0} sm={6}>
            <div
              className="landing-image"
              style={{
                width: '100%',
                height: '100vh',
                backgroundImage: `url('https://md-sports.s3.ap-south-1.amazonaws.com/background.jpeg')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: '0.9',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            ></div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              backgroundImage: {
                xs: `url('https://md-sports.s3.ap-south-1.amazonaws.com/background.jpeg')`,
                sm: 'none',
              },
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card
              variant="outlined"
              sx={{
                width: { xs: '100vw', sm: '50vw' },
                height: '100vh',
                opacity: '0.8',
                border: 'none',
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <CardHeader
                sx={{ textAlign: 'center' }}
                title="  Predict The Winner"
              />
              <CardContent>
                <form
                  onSubmit={e => handleSignin(e)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: 500,
                  }}
                >
                  <InputLabel htmlFor="email" sx={{ alignSelf: 'flex-start' }}>
                    Email *
                  </InputLabel>
                  <InputField
                    id="email"
                    type="email"
                    fullWidth
                    margin="normal"
                    onChange={handleChange('email')}
                    value={values.email}
                  />

                  <InputLabel
                    htmlFor="password"
                    sx={{ alignSelf: 'flex-start' }}
                  >
                    {' '}
                    Password *
                  </InputLabel>
                  <InputField
                    id="password"
                    type="password"
                    fullWidth
                    margin="normal"
                    placeholder="Enter password"
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        handleSignin(event);
                      }
                    }}
                    onChange={handleChange('password')}

                  />
                  <Typography
                      variant="body2"
                      style={{
                       marginTop:0,
                       cursor: 'pointer',
                       alignSelf: 'flex-end',
                       fontStyle:'italic',
                      }}
                      onClick={handleForgotPassword}
                    >
                      Forgot password?
                    </Typography> 
                  <Grid item sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    marginTop: 5
                  }}>
                    

                    <Grid sx={{display:'block',
                        textAlign:'start',
                        }}>
                    <Typography
                      variant="body2"
                      
                    >
                      New user?
                      <span
                        style={{
                          cursor: 'pointer',
                          marginLeft: '5px',
                        }}
                        onClick={handleToggle}
                      >
                        Sign Up
                      </span>
                    </Typography>
                    </Grid>
                    {/* <Typography
                      variant="body2"
                      style={{
                       marginTop:0,
                       cursor: 'pointer',
                       //marginLeft:'200px'
                       
                      }}
                      onClick={handleForgotPassword}
                    >
                      Forgot password?
                    </Typography>  */}

                    <Button
                      id="submit"
                      type="submit"
                      value="Sign In"
                      variant="contained"
                      sx={{ marginTop: 2 }}
                      style={{ alignSelf: 'flex-end' }}
                    >
                      Sign in
                    </Button>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Auth;
