import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  CardHeader,
  CardContent,
  TextField,
  Card,
  Grid,
  Button,
  Container,
  InputLabel,
  Box,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { theme } from '../../utils/theme';
import {resetPassword } from "../../redux/auth/auth.actions";


const InputField = styled(TextField)`
  border-radius: 8px;
  margin-top: 8px;
  color: ${theme.palette.primary.contrastText},
  margin-bottom: 16px;
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &:hover fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-focused fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-error fieldset {
      border-color: #your-error-color; /* Error state */
    }
  }
`;


const ResetPassword = props => {
  //   const theme = useTheme();
  const { uid } = useParams();
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false)
  const [values, setValues] = useState({
    new_password: '',
    confirm_password: '',
});
console.log("uid:",uid)
console.log("token:",token)
 
  const handleResetPassword = () => {
    
      
    if (!values.new_password || !values.confirm_password) {
      toast.warning("Please provide Password", {
        position: "top-center",
        autoClose: 3000,
      });
      return ;
    }
    if (values.new_password !== values.confirm_password) {
      toast.warning("Passwords don't match!", {
        position: "top-center",
        autoClose: 3000,
      });
    } else {
      dispatch(resetPassword(values.new_password, values.confirm_password,uid,token));
      setSuccess(true);  
    }
  
}; 
const handleCancel= () =>{
  navigate('/login');
}

  
   const handleChange = prop => e => {
     setValues({ ...values, [prop]: e.target.value });
   };

  useEffect(() => {
    if (success === true) {
      navigate('/login');
      
    }
}, [success, navigate]);

  return (
    <>
    <Container
        sx={{
          height: '100vh',
          padding: '0!important',
        }}
        maxWidth={'100vw'}
      >
        <Grid container>
          <Grid item xs={0} sm={6}>
            <div
              className="landing-image"
              style={{
                width: '100%',
                height: '100vh',
                backgroundImage: `url('https://md-sports.s3.ap-south-1.amazonaws.com/background.jpeg')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: '0.9',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            ></div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              backgroundImage: {
                xs: `url('https://md-sports.s3.ap-south-1.amazonaws.com/background.jpeg')`,
                sm: 'none',
              },
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card
              variant="outlined"
              sx={{
                width: { xs: '100vw', sm: '50vw' },
                height: '100vh',
                opacity: '0.8',
                border: 'none',
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <CardHeader
                sx={{ textAlign: 'center' }}
                title="  Reset Password"
              />
              <CardContent>
              
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: 500,
                  }}
                >

                  <InputLabel htmlFor="email" sx={{ alignSelf: 'flex-start' }}>
                    Password *
                  </InputLabel>
                  <InputField
                    id="new_password"
                    type="password"
                    fullWidth
                    margin="normal"
                    onChange={handleChange('new_password')}
                    value={values.new_password}
                  />
                  <InputLabel htmlFor="email" sx={{ alignSelf: 'flex-start' }}>
                    Confirm Password *
                  </InputLabel>
                  <InputField
                    id="confirm_password"
                    type="password"
                    fullWidth
                    margin="normal"
                    onChange={handleChange('confirm_password')}
                    value={values.confirm_password}
                  />
                  <Grid item sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    gap: 3,
                     }}>
                    
                    <Button
                      id="submit"
                      type="submit"
                      value="Cancel"
                      variant="contained"
                      sx={{ marginTop: 2, alignContent:'center', width: '80px' }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  {/* </Grid>
                  <Grid item sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                     }}> */}
                    
                    <Button
                      id="submit"
                      type="submit"
                      value="Reset"
                      variant="contained"
                      sx={{ marginTop: 2, alignContent: 'center', width: '80px' }}
                      onClick={e => handleResetPassword(e)}
                    >
                      Save
                    </Button>
                  </Grid>
                  </Box>
           
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
  </>
  );
};

export default ResetPassword;
