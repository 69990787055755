import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from "../../sharedComponents/Container";
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from "@mui/material/TableCell";
import InfoIcon from '@mui/icons-material/Info';
import { styled } from "@mui/material/styles";
import { useParams } from 'react-router-dom';
import { addTournamentsToUser, getMatchSchedules, getTopGroups, getTopPredictors, getTopUser, getTournamentDetails, getUserGroups } from '../../redux/tournaments/tournament.actions'
import { useSelector, useDispatch } from "react-redux"
import { Typography, Button, Grid, Breadcrumbs, Link, Tooltip, Divider, MenuItem, Select, FormControl, useMediaQuery, InputLabel, Menu  } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { formatDate, formatTime } from '../../utils/dates';
import { assignRanks } from '../../utils/ranking';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ListItemButton, ListItemText, Box } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#363636",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const TournamentDetails = () => {
    
    const { tournament_id } = useParams();
    const [userPoints, setUserPoints] = useState(0);
    const [userCoins, setUserCoins] = useState(0);
    const [isParticipated, setIsParticipated] = useState(false);
    const [activeTab, setActiveTab] = useState(localStorage.getItem(`activeTab_${tournament_id}`) || 'schedules');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const topUserList = useSelector((state) => state.tournaments.topUserList);
    let rankedTopUserList = [];
    if (topUserList.length > 0) {
        rankedTopUserList = assignRanks(topUserList);
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [playedMessageDisplayed, setPlayedMessageDisplayed] = useState(false);
    const topGroupList = useSelector((state) => state.tournaments.topGroupList);
    let rankedTopGroupList = [];
    if (topGroupList.length > 0) {
        rankedTopGroupList = assignRanks(topGroupList);
    }
    const topPredictors = useSelector((state) => state.tournaments.topPredictors);
    const userGroupList = useSelector((state) => state.tournaments.userGroupList);
    const matchSchedules = useSelector((state) => state.tournaments.matchSchedules);
    const t_Details = useSelector((state) => state.tournaments.tournamentDetails);
    const [openFilterModal, setOpenFilterModal] = useState(false); // State for controlling the modal
    const [selectedFilters, setSelectedFilters] = useState('all');
    const [fill, setFill] = React.useState('');
   
    console.log('filter',selectedFilters)
    const handleOpenFilterModal = (event) => {
        setOpenFilterModal(true);
        setAnchorEl(event.currentTarget);
    };
    const handleChange = (event) => {
        setFill(event.target.value);
      };
    
    const handleSelectFilter = (filter) => {
       setSelectedFilters(filter)
       
       setOpenFilterModal(false);
       setAnchorEl(null);
      
   };
   const handleCloseFilterModal = () => {
        setOpenFilterModal(false);
        setAnchorEl(null);
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        localStorage.setItem(`activeTab_${tournament_id}`, newValue);
    };

    const handleGroupMembersView = (group_id) => {
        localStorage.setItem("tournament_id", tournament_id)
        navigate(`/groups/${group_id}/group_members`)
    }

    const coins = "Coins : -  Units to spend for playing matches.\n Points : - Units indicating your earnings by playing the matches. The rankings will be decided based on the points.";
    // const points = "Units indicating your earnings by playing the matches. The rankings will be decided based on the points.";

    const handleUserMatch = (user) => {
        localStorage.setItem("tournament_id", tournament_id)
        localStorage.setItem("userId", user.id)
        localStorage.setItem("user_fname", user.first_name)
        localStorage.setItem("user_lname", user.last_name)
        navigate(`/tournaments/${tournament_id}/user/${user.id}`)
    }

    const handleClick = (match) => {
        localStorage.setItem("sport_id", match.sport_id)
        navigate(`/matches/${match.id}`);
    };

    useEffect(() => {
        dispatch(getTournamentDetails(tournament_id));
    }, [dispatch, tournament_id]);
    

    useEffect(() => {
        if (isParticipated) {
        dispatch(getTournamentDetails(tournament_id));
        }
    }, [dispatch, isParticipated, tournament_id]);
    

    useEffect(() => {
        dispatch(getTopUser(tournament_id));
        dispatch(getTopGroups(tournament_id));
        dispatch(getTopPredictors(tournament_id));
        dispatch(getUserGroups(tournament_id));
        dispatch(getMatchSchedules(tournament_id));
        setUserPoints(t_Details?.user_details?.user_points);
        setUserCoins(t_Details?.user_details?.user_coins);
        setIsParticipated(t_Details?.user_details?.is_participated);
    }, [dispatch, tournament_id, t_Details]);

    const handleAddTournament = () => {
        dispatch(addTournamentsToUser(tournament_id));
        setIsParticipated(true);
    };
    
    useEffect(() => {
        localStorage.removeItem("userId");
    });

    // if you use back button
    // const handleBack = () => {
    //     navigate(-1);
    // };

    const [sortedData, setSortedData] = useState(null);
    const [sortedByVenue, setSortedByVenue] = useState(false);

    const sortByDate = () => {
        const sorted = [...matchSchedules].sort((a, b) => {
            const dateTimeA = new Date(`${a.start_date}T${a.start_time}`);
            const dateTimeB = new Date(`${b.start_date}T${b.start_time}`);
          return dateTimeB - dateTimeA;
        });
        setSortedData(sorted);
        setSortedByVenue(false);
      };

      const sortBy = (data, name) => {
        console.log('name', name)
        if (sortedByVenue) {
            setSortedData(null); // Clear sorting
            setSortedByVenue(false); // Reset sortedByVenue flag
        } else {
            const sorted = [...data].sort((a, b) => {
                if (a[name] < b[name]) return -1;
                if (a[name] > b[name]) return 1;
                return 0;
            });
            setSortedData(sorted);
            setSortedByVenue(true); // Set sortedByVenue flag
        }
    };
    
    const clearSort = () => {
        setSortedData(null);
        if (sortedByVenue) {
        setSortedByVenue(false)
        } return;
    };

    const sortedContent = sortedData ? sortedData : matchSchedules;

    return (
        <Container>
            <Grid item xs={12} mt={2}>
                <Breadcrumbs
                    separator="›"
                    aria-label="breadcrumb"
                    sx={{
                        color: "black",
                        fontSize: "larger",
                        ml: { md: "60px", sm: "40px", xs: "10px" },
                    }}
                >
                    <Link
                        underline="hover"
                        key="1"
                        color="inherit"
                        href="/dashboard"
                        fontSize={"bolder"}
                    >
                        {"› "}Dashboard
                    </Link>
                    <Link
                        fontSize={"bolder"}
                        underline="hover"
                        key="1"
                        color="inherit"
                        href={`/tournaments/${tournament_id}`}
                    >
                        {t_Details.name}
                    </Link>
                    {isParticipated === false && (
                        <Button
                            sx={{
                                bgcolor: '#dabe2cfc',
                                size: 'small',
                                color: 'black',
                                fontWeight: 700,
                                height: '25px',
                            }}
                            onClick={handleAddTournament}
                        >
                            JOIN
                        </Button>
                    )}
                   
                   {/* use this if required a back button */}
                    {/* {['schedules', 'mygroups', 'topUsers', 'topGroups'].includes(activeTab) && (
                        <Button onClick={handleBack}>Back</Button>
                    )} */}    

                </Breadcrumbs>
            </Grid>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{ display: { xs: 'flex', sm: 'block' } }}
            >
                <Tab label="Schedule" value="schedules" />
                <Tab label="My Groups" value="mygroups" />
                <Tab label="Top Users" value="topUsers" />
                <Tab label="Top Groups" value="topGroups" />
                <Tab label="Top Predictors" value="topPredictors" />
            </Tabs>
            {activeTab !== 'topPredictors' && ( 
              <Grid sx={{ maxWidth: (activeTab === "schedules") ? "800px" : "600px", minWidth: "300px", marginTop: "20px", display: "flex" , justifyContent:"space-between" }}>
                <Grid alignContent={"end"}>
                <Typography  variant="h7" fontWeight={600} sx={{mr:2}}>
                    Coins: {userCoins} 
                </Typography>|
                <Typography  variant="h7" fontWeight={600} sx={{ml:2}}>
                    Points: {userPoints} 
                    
                    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{coins}</span>} arrow placement="top-start" sx={{ verticalAlign: 'text-top', marginLeft: '5px', color: '#3ca335' }}>
                        <InfoIcon fontSize="1.2rem"  />
                    </Tooltip>
                </Typography>
                </Grid>
              
                <Grid sx={{display:"flex", marginLeft:5, }}>
                
                {activeTab === "schedules" && (
                    <>               
                     <FormControl variant="standard" sx={{display: 'flex', alignItems: 'center', minWidth: 70 }}  hiddenLabel>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        {<FilterAltIcon sx={{mr:1}} />}
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={fill}
                            defaultValue="all"
                            onChange={handleChange}
                            sx={{
                                color: "black",
                                '& #demo-simple-select-standard': {
                                  bgcolor: 'transparent' 
                                },
                              }}
                              disableUnderline
                        >           
                            <MenuItem value={""} onClick={() => handleSelectFilter('all')}>Clear Filter
                            </MenuItem>
                            <MenuItem value={'Matches-To play'} onClick={() => handleSelectFilter('play')}>  
                                Matches     - To play
                            </MenuItem>
                            <MenuItem value={'played'} onClick={() => handleSelectFilter('played')} >
                                Played Matches
                            </MenuItem>
                            <MenuItem value={'missed'} onClick={() => handleSelectFilter('missed')}>
                            Missed Matches 
                            </MenuItem>
                            <MenuItem value={'upcoming'} onClick={() => handleSelectFilter('upcoming')}>
                                Upcoming Matches
                            </MenuItem>                    
                        </Select>
                        </div>
                    </FormControl>
                                    
                    </>
                )}
                </Grid>
            </Grid>
            )}

            {activeTab === 'mygroups' && userGroupList.length === 0 && (
                <Typography variant="body1" align="center">There are no linked groups.</Typography>
            )}

            {activeTab === 'topUsers' && rankedTopUserList.length === 0 && (
                <Typography variant="body1" align="center">There are no linked users.</Typography>
            )}

            {activeTab === 'topGroups' && rankedTopGroupList.length === 0 && (
                <Typography variant="body1" align="center">There are no linked groups.</Typography>
            )}

            {activeTab === 'schedules' && matchSchedules.length === 0 && (
                <Typography variant="body1" align="center">There are no linked match schedules.</Typography>
            )}

            {(activeTab !== 'mygroups' || userGroupList.length > 0) &&
                (activeTab !== 'topUsers' || rankedTopUserList.length > 0) &&
                (activeTab !== 'schedules' || matchSchedules.length > 0) && 
                (activeTab !== 'topPredictors' ) &&
                (activeTab !== 'topGroups' || rankedTopGroupList.length > 0) && (
                    <TableContainer sx={{ maxWidth: (activeTab === "schedules") ? "800px" : "600px", minWidth: "300px", marginTop: "20px" }} component={Paper}>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow key={"head"}>
                                    {(activeTab !== 'mygroups' && activeTab !== 'schedules') && <StyledTableCell
                                        sx={{
                                            padding: "10px 16px",
                                            color: (theme) => theme.palette.text.secondary,
                                        }}
                                        align="left"
                                    >
                                        {"Rank"} 
                                    </StyledTableCell>}
                                    <StyledTableCell
                                        sx={{
                                            padding: "10px 0px 10px 16px",
                                            color: (theme) => theme.palette.text.secondary,
                                        }}
                                        align="left"
                                    >
                                        {activeTab === 'schedules' ? "Date & Time"  : "Name"}
                                        {activeTab === 'schedules' && (
                                            sortedByVenue ? 
                                                <ArrowDropUpIcon 
                                                onClick={sortedData ? clearSort : sortByDate}
                                                sx={{verticalAlign: 'middle', cursor: 'pointer'}}
                                                /> 
                                                : 
                                                <ArrowDropDownIcon 
                                                onClick={sortedData ? clearSort : sortByDate}
                                                sx={{verticalAlign: 'middle', cursor: 'pointer'}}
                                                />
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        sx={{
                                            padding: "10px 16px",
                                            color: (theme) => theme.palette.text.secondary,
                                        }}
                                        align="center"
                                    >
                                        {activeTab === 'schedules' ? "Matches" : "Points"}
                                    </StyledTableCell>
                                    {(activeTab === 'mygroups' || activeTab === 'topUsers') && (
                                            <StyledTableCell
                                                sx={{
                                                    padding: "10px 16px",
                                                    color: (theme) => theme.palette.text.secondary,
                                                }}
                                                align="center"
                                            >
                                                Action
                                            </StyledTableCell>
                                        )}
                                    {activeTab === 'schedules' && (
                                        <>
                                            {/* <StyledTableCell
                                                    sx={{
                                                        padding: "10px 16px",
                                                        color: (theme) => theme.palette.text.secondary,
                                                    }}
                                                    align="center"
                                                >
                                                    Team A
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    sx={{
                                                        padding: "10px 16px",
                                                        color: (theme) => theme.palette.text.secondary,
                                                    }}
                                                    align="center"
                                                >
                                                    Team B
                                                </StyledTableCell> */}
                                            <StyledTableCell
                                                sx={{
                                                    padding: "10px 16px",
                                                    display: { xs: 'none', sm: 'revert' },
                                                    color: (theme) => theme.palette.text.secondary,
                                                }}
                                                align="left"
                                            >
                                                Venue 
                                                {sortedByVenue ? 
                                                    <ArrowDropUpIcon 
                                                        onClick={(name) => sortBy(matchSchedules, name='venue')} 
                                                        sx={{verticalAlign: 'middle', cursor: 'pointer'}}
                                                    /> 
                                                    : 
                                                    <ArrowDropDownIcon 
                                                        onClick={(name) => sortBy(matchSchedules, name='venue')}
                                                        sx={{verticalAlign: 'middle', cursor: 'pointer'}}
                                                    />
                                                }
                                            </StyledTableCell>
                                            {isParticipated === true && (
                                                <StyledTableCell
                                                    sx={{
                                                        padding: "10px 16px",
                                                        color: (theme) => theme.palette.text.secondary,
                                                    }}
                                                    align="right"
                                                >
                                                    Points
                                                </StyledTableCell>
                                            )}
                                        </>
                                    )}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {activeTab === 'mygroups' && userGroupList.map((group, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="left" scope="row">
                                            {group.group?.name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="center">
                                            {group.points || 0}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => handleGroupMembersView(group.group.id)}
                                            >
                                                View
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow >
                                ))}
                                {activeTab === 'topUsers' && rankedTopUserList.map((user, index) => (
                                    (user.points > 0  && user.points !== null ) && (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="left" scope="row">
                                            {user.rank}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="left" scope="row">
                                            {`${user.user.first_name} ${user.user.last_name}`}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="center">
                                            {user.points}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => handleUserMatch(user.user)}
                                            >
                                                View
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                                ))}
                                {activeTab === 'topGroups' && rankedTopGroupList.map((group, index) => (
                                    (group.points > 0  && group.points !== null ) && (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="left" scope="row">
                                            {group.rank}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="left" scope="row">
                                            {group.group?.name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="center">
                                            {group.points || 0}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    )
                                ))}
                                {activeTab === 'schedules' && (
                                <>
                                    {sortedContent.some(match =>
                                    selectedFilters === 'all' ||
                                    (selectedFilters === 'play' && match.state === 'active' && !match.is_time_out && !match.is_user_played) ||
                                    (selectedFilters === 'played' && match.is_user_played) ||
                                    (selectedFilters === 'missed' && match.is_time_out && !match.is_user_played) ||
                                    (selectedFilters === 'upcoming' && match.state !== 'active')
                                    ) ? (
                                    sortedContent.map((match, index) => {
                                        if (
                                        selectedFilters === 'all' ||
                                        (selectedFilters === 'play' && match.state === 'active' && !match.is_time_out && !match.is_user_played) ||
                                        (selectedFilters === 'played' && match.is_user_played) ||
                                        (selectedFilters === 'missed' && match.is_time_out && !match.is_user_played) ||
                                        (selectedFilters === 'upcoming' && match.state !== 'active')
                                        ) {
                                        return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="left" scope="row">
                                        {formatDate(match?.start_date, match?.start_time)}
                                        <br />
                                        {formatTime(match?.start_date, match?.start_time)}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: "10px 16px" }} align="center">
                                        {match?.match_details.team1} <br /> {"VS"} <br />
                                        {match?.match_details.team2}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ padding: "10px 16px", display: { xs: 'none', sm: 'revert' } }} align="left" scope='row'>
                                        {match?.venue}
                                        </StyledTableCell>
                                        {isParticipated === true && (
                                            <StyledTableCell sx={{ padding: "10px 16px" }} align="right" >
                                        {match.state === 'active' ? (
                                            <>
                                                {match?.match_points > 0 ? (
                                                    <Button size="sm" 
                                                        sx={{
                                                            backgroundColor: '#83c098',
                                                            // border:' 0.5px solid black',
                                                            height:'25px',
                                                            fontWeight:'700',
                                                            color:'white',
                                                            // width: '50px',
                                                            '&:hover': {
                                                                backgroundColor: '#83c098' ,
                                                            }
                                                        }}
                                                        onClick={() => handleClick(match)}
                                                    >
                                                        {match?.match_points}
                                                    </Button> ) : (
                                                    <>
                                                    {match?.is_time_out === true ? ( 
                                                    <Button size="sm"
                                                        sx={{
                                                            backgroundColor: match?.is_user_played === true ? '#dabe2cfc' : '#FF6347',
                                                            border:' 0.5px solid',
                                                            height:'25px',
                                                            fontWeight:'bold',
                                                            color:'white',
                                                            fontSize: "0.70rem",
                                                            '&:hover': {
                                                                backgroundColor: match?.is_user_played === true ? '#dabe2cfc' : '#FF6347' ,
                                                            }
                                                        }}
                                                        onClick={() => (match?.is_user_played === true) && handleClick(match) }
                                                        
                                                    >
                                                        {match?.is_user_played === true ?  "Locked" : "-200"}
                                                    </Button>
                                                    ) : ( 
                                                    <Button size="sm" 
                                                        sx={{
                                                            backgroundColor: match?.is_user_played === true ? '#65b17f' : '#1E90FF' , //  #802500 -blue
                                                            border:' 0.5px solid',
                                                            height:'25px',
                                                            fontWeight:'bold',
                                                            color:'white',
                                                            width: '60px',
                                                            fontSize: "0.70rem",
                                                            '&:hover': {
                                                                backgroundColor: match?.is_user_played === true ? '#65b17f' : '#1E90FF' ,
                                                            }
                                                        }}
                                                        onClick={() => handleClick(match)}
                                                    >
                                                        {match?.is_user_played === true ? "Played" : "Play"}
                                                    </Button>
                                                    )}</>
                                                )}
                                                </>
                                        ) : (
                                            <Button sx={{
                                                backgroundColor: '#DCDCDC',
                                                height:'25px',
                                                fontWeight:'bold',
                                                color:'#8c8484',
                                                width: '60px',
                                                fontSize: "0.70rem",
                                                '&:hover': {
                                                    backgroundColor: '#DCDCDC' ,
                                                }
                                            }}
                                            >
                                                N/A
                                            </Button>
                                        )}
                                            </StyledTableCell>
                                        )}
                                    </StyledTableRow>
                                        );
                                    }
                                    return null;
                                    })
                                    ) : (
                                        
                                    <Typography variant="body1" marginTop={5} marginLeft={30} marginBottom={3}>
                                    No matches available for the selected filter.
                                    </Typography>
                                    )}
                                </>
                               
                                )}


                               </TableBody>
                           </Table>
                       </TableContainer>
                   )
                   
                   }
            
            
                {activeTab === 'topPredictors' && (
                    <Grid>
                        <TopPredictors
                            topPredictor={topPredictors}
                        />
                   </Grid>
                )}
        </Container>
    )
}

export default TournamentDetails;

const TopPredictors = ({ topPredictor }) => {
    const [activeQuestion, setActiveQuestion] = useState(
      localStorage.getItem('activeTabs2') || topPredictor[0]?.question
    );
  
    const handleQuestionClick = (question) => {
      setActiveQuestion(question);
      localStorage.setItem('activeTabs2', question);
    };

    const handleChange = (event) => {
        setActiveQuestion(event.target.value);
        localStorage.setItem('activeTabs2', event.target.value);
    };

    const isMobile = useMediaQuery('(max-width:600px)');
  
    return (
      <>
        <Box 
            sx={{ 
                gap: 2, 
                display: { xs: 'block', sm: 'flex' }, 
                justifyContent: 'center', 
                margin: 0,
                width: { xs: '100%', md: '90%', lg: '80%'},
                mt: 3
            }}
        >
          <Grid item sx={{ width:{ xs:'100%', sm:'50%', md:'40%'}}}>
              
                {/* Use MenuItem for mobile screens */}
                {isMobile ? (
                    <FormControl sx={{ mt:4, mb:4, minWidth: '310px', maxWidth: '310px' }}>
                        <InputLabel id="demo-simple-select-label">Questions</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={activeQuestion}
                        onChange={handleChange}
                        label="Question"
                        minWidth={'300px'}
                        // sx={{ p: '0 10px' }}
                    >
                    {topPredictor.map((item, index) => (
                    <MenuItem key={index} value={item.question} >
                    {item.question}
                    </MenuItem>
                    ))}
                    </Select>
                    </FormControl>
                ) : (
                    <>
                    <Typography variant="h6"  fontWeight={600} padding={1} mb={1} bgcolor={'#daece1'}>
                        Questions
                    </Typography>
                {topPredictor.map((item, index) => (
                    <>
                <ListItemButton key={index}
                    aria-selected={item.question === activeQuestion}
                    onClick={() => handleQuestionClick(item.question)}
                    sx={{
                        backgroundColor: item.question === activeQuestion && 'primary.main',
                        color: item.question === activeQuestion && 'common.white',
                        WebkitTextStroke: item.question === activeQuestion && 'thin',
                        padding: 1,
                        pointerEvents: 'auto',
                        ':hover': {
                        backgroundColor: item.question === activeQuestion ? 'primary.main' : '#e1e1e1',
                        color: item.question === activeQuestion ? 'common.white' : 'black',
                        },
                    }}
                    >
                    <ListItemText primary={item.question}  />
                    <ArrowRightIcon />
                    </ListItemButton>
                <Divider />
                </>
            ))}
                    </>
                )}
            </Grid>
          <Grid container sm={9} sx={{ display: 'block' }}>
            <Typography variant="h6" padding={1} fontWeight={550} mb={1.5}>
              Top Predictors
            </Typography>
            <TableContainer sx={{ maxWidth: '500px', minWidth: '300px', alignContent: 'center' }} component={Paper}>
              <Table sx={{ width: '100%' }}>
                <TableHead>
                  <TableRow key="head">
                    <StyledTableCell
                      sx={{
                        padding: '10px 16px',
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="center"
                    >
                      Rank
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        padding: '10px 16px', pl:'35px',
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align="left"
                    >
                      Name
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        padding: '10px 16px',
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      align='center'
                    >
                      Count
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {topPredictor.map((question, index) => (
                    activeQuestion === question.question && (
                    <React.Fragment key={index}>
                        {question.top_predictors.map((item, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell sx={{ padding: '10px 16px' }} align="center" scope="row">
                            {item.rank}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '10px 16px' }} align="left">
                            {item.user__first_name + ' ' + item.user__last_name}
                            </StyledTableCell>
                            <StyledTableCell sx={{ padding: '10px 16px' }} align="center">
                            {item.correct_result_count}
                            </StyledTableCell>
                        </StyledTableRow>
                        ))}
                    </React.Fragment>
                    )
                ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </>
    );
  };

