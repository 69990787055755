import { takeEvery, put } from 'redux-saga/effects';
import { GET, PATCH, POST } from '../../axios';
import GroupActionType from './group.types';
import * as GroupActions from './group.actions';
import { setError } from '../actions/action.actions';
import urls from '../urls';
import { toast } from 'react-toastify';

function* fetchUserGroupsSaga() {
  try {
    const userId = localStorage.getItem('user_id');
    const data = yield GET(`users/${userId}/groups/`);

    if (data && data.data) {
      yield put(GroupActions.setUserGroups(data.data));
    } else {
      let errorMessage =
        'An error occurred while fetching group list. Please try again later.';
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during group list fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* fetchGroupsSaga(group) {
  try {
    const data = yield GET(`${urls.groups}${group.group_id}`);

    if (data && data.data) {
      yield put(GroupActions.setGroup(data.data));
    } else {
      let errorMessage =
        'An error occurred while fetching group details. Please try again later.';
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during group details fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* fetchUserGroupMembersSaga(action) {
  try {
    const data = yield GET(`${urls.groups}${action.group_id}/group_members`);

    if (data && data.data) {
      yield put(GroupActions.setUserGroupMembers(data.data));
    } else {
      let errorMessage =
        'An error occurred while fetching group members list. Please try again later.';
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during group members list fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* fetchGroupTournamentsSaga(action) {
  try {
    const data = yield GET(`${urls.groups}${action.group_id}/tournaments/`);

    if (data && data.data) {
      const tournamentDetails = data.data.map(item => item.tournament_details);
      yield put(GroupActions.setGroupTournaments(tournamentDetails));
    } else {
      let errorMessage =
        'An error occurred while fetching group tournaments list. Please try again later.';
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during group tournaments list fetching:', error);
    yield put(setError('An unexpected error occurred.'));
    console.error('Error during group tournaments list fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* fetchExitGroupsSaga(inputs) {
  try {
    const userId = inputs.user_id;
    console.log('user =>', userId);
    let url = `${urls.groups}${inputs.group_id}/exit_group/`;
    if (userId) url += `?user=${userId}`;
    const data = yield PATCH(url);

    if (data && (data.success === true || data.success === 'True')) {
      toast.success('User removed from the Group successfully', {
        position: 'top-center',
        autoClose: 5000,
      });
    } else {
      if (data.message) {
        toast.warning(data.message, {
          position: 'top-center',
          autoClose: 5000,
        });
        return;
      }
      yield put(setError(data.message));
    }
  } catch (error) {
    console.error('Error during group tournaments list fetching:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* addGroupSaga(name_object) {
  try {
    const data = yield POST(`${urls.groups}`, {
      name: name_object.name,
      public: false,
    });

    if (data?.success === true) {
      toast.success('Group created successfully', {
        position: 'top-center',
        autoClose: 5000,
      });
      yield put(GroupActions.getUserGroups());
    } else {
      let errorMessage =
        'An error occurred during creating Group. Please try again later.';
      if (data.message) {
        errorMessage = data.message;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error('Error during group creating group:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

function* updateGroupActiveStatusSaga(action) {
  try {
    const { group_id, active } = action;
    const data = yield PATCH(`${urls.groups}${group_id}`, { active });

    if (data && (data.success === true || data.success === 'True')) {
      toast.success('Group active status updated successfully', {
        position: 'top-center',
        autoClose: 5000,
      });
    } else {
      if (data.message) {
        toast.warning(data.message, {
          position: 'top-center',
          autoClose: 5000,
        });
        return;
      }
      yield put(setError(data.message));
    }
  } catch (error) {
    console.error('Error during updating group active status:', error);
    yield put(setError('An unexpected error occurred.'));
  }
}

export function* groupSaga() {
  yield takeEvery(GroupActionType.FETCH_USER_GROUPS, fetchUserGroupsSaga);
  yield takeEvery(GroupActionType.FETCH_GROUPS, fetchGroupsSaga);
  yield takeEvery(
    GroupActionType.FETCH_USER_GROUP_MEMEBERS,
    fetchUserGroupMembersSaga,
  );
  yield takeEvery(
    GroupActionType.FETCH_GROUP_TOURNAMENTS,
    fetchGroupTournamentsSaga,
  );
  yield takeEvery(GroupActionType.EXIT_GROUP, fetchExitGroupsSaga);
  yield takeEvery(GroupActionType.ADD_GROUP, addGroupSaga);
  yield takeEvery(GroupActionType.UPDATE_GROUP_ACTIVE_STATUS, updateGroupActiveStatusSaga);
}
