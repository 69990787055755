import GroupActionType from './group.types';

const INITIAL_STATE = {
  userGroups: [],
  userGroupMembers: [],
  groupTournaments: [],
  group: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GroupActionType.SET_USER_GROUPS:
      return {
        ...state,
        userGroups: action.payload,
      };
    case GroupActionType.SET_USER_GROUP_MEMBERS:
      return {
        ...state,
        userGroupMembers: action.payload,
      };
    case GroupActionType.SET_GROUP_TOURNAMENTS:
      return {
        ...state,
        groupTournaments: action.payload,
      };
    case GroupActionType.SET_GROUPS:
      return {
        ...state,
        group: action.payload,
      };
       case GroupActionType.UPDATE_GROUP_ACTIVE_STATUS:
      return {
        ...state,
        group: Array.isArray(state.group)
          ? state.group.map(group =>
              group.id === action.group_id ? { ...group, active: action.active } : group
            )
          : [],
      };
    default:
      return state;
  }
};

export default reducer;
