import React, { useEffect } from "react";
import Container from "../../sharedComponents/Container";
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { getUserGroupsMembers } from "../../redux/tournaments/tournament.actions";
import { Grid, Breadcrumbs, Link, Tab, Button } from "@mui/material";
import { assignRanks } from '../../utils/ranking';
import { StyledTableCell, StyledTableRow } from "../../utils/tables";


const GroupMembersDetails = () => {
    const { group_id } = useParams();
    const tournament_id = localStorage.getItem("tournament_id")
    const tournamentName = localStorage.getItem("tournament_name")
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userGroupMembersList = useSelector(state => state.tournaments.userGroupMembersList);
    let rankedUserGroupMembersList = [];
    if (userGroupMembersList.length > 0) {
        rankedUserGroupMembersList = assignRanks(userGroupMembersList);
    }

    useEffect(() => {
        const fetchData = async () => {
            dispatch(getUserGroupsMembers(tournament_id, group_id))
        };
        fetchData();
    }, [dispatch, tournament_id, group_id]);

    const handleUserMatch = (user) => {
        localStorage.setItem("tournament_id", tournament_id)
        localStorage.setItem("userId", user.id)
        localStorage.setItem("user_fname", user.first_name)
        localStorage.setItem("user_lname", user.last_name)
        navigate(`/tournaments/${tournament_id}/user/${user.id}`)
    }

    return (
        <Container>
            <Grid item xs={12}>
                <Breadcrumbs
                    separator="›"
                    aria-label="breadcrumb"
                    sx={{
                        color: "black",
                        fontSize: "larger",
                        ml: { md: "60px", sm: "40px", xs: "10px" },
                    }}
                >
                    <Link
                        underline="hover"
                        key="1"
                        color="inherit"
                        href="/dashboard"
                        fontSize={"bolder"}
                    >
                        {"› "}Dashboard
                    </Link>
                    <Link
                        fontSize={"bolder"}
                        underline="hover"
                        key="1"
                        color="inherit"
                        href={`/tournaments/${tournament_id}`}
                    >
                        {tournamentName}
                    </Link>
                    <Link
                        fontSize={"bolder"}
                        underline="hover"
                        key="1"
                        color="inherit"
                    >
                        Group Members
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Tab
                label="Group Members"
            />
            <TableContainer sx={{ maxWidth: "600px", minWidth: "300px" }} component={Paper}>
                <Table sx={{ width: "100%" }}>
                    <TableHead>
                        <TableRow key={"head"}>
                            <StyledTableCell
                                sx={{
                                    padding: "10px 16px",
                                    color: (theme) => theme.palette.text.secondary,
                                }}
                                align="center"
                            >
                                Rank
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    padding: "10px 16px",
                                    color: (theme) => theme.palette.text.secondary,
                                }}
                                align="center"
                            >
                                Member name
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    padding: "10px 16px",
                                    color: (theme) => theme.palette.text.secondary,
                                }}
                                align="center"
                            >
                                Points
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    padding: "10px 16px",
                                    color: (theme) => theme.palette.text.secondary,
                                }}
                                align="center"
                            >
                                Action
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rankedUserGroupMembersList.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={2} align="center">
                                    There are no linked group members
                                </TableCell>
                            </TableRow>
                        ) : (
                            rankedUserGroupMembersList.map((user, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell sx={{ padding: "10px 16px" }} align="center" scope="row">
                                        {user.rank}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ padding: "10px 16px" }} align="center" scope="row">
                                        {`${user.member_details.first_name} ${user.member_details.last_name}`}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ padding: "10px 16px" }} align="center">
                                        {user.points}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ padding: "10px 16px" }} align="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => handleUserMatch(user.member_details)}
                                            >
                                                View
                                            </Button>
                                        </StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}

export default GroupMembersDetails;