import QuestionActionType from "./question.type";

const INITIAL_STATE = {
  questions: null,
  mainOptions: [],
  players: [],
  answers: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QuestionActionType.SET_QUESTION:
      return {
        ...state,
        questions: action.payload,
      };
    case QuestionActionType.SET_MAIN_OPTIONS:
      return {
        ...state,
        mainOptions: action.payload,
      };
    case QuestionActionType.SET_PLAYERS:
      return {
        ...state,
        players: action.payload,
      };
    case QuestionActionType.SET_ANSWERS:
      return {
        ...state,
        answers: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
