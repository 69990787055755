import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.css'; // Import custom CSS for carousel styling

const Carousel = ({ slides }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000, 
        slidesToShow: 3, 
        slidesToScroll: 1,
        // autoplay: false,
        autoplaySpeed: 3000, 
        fade: false, 
        cssEase: 'ease-in-out', // Use ease-in-out transition
        responsive: [
          {
            breakpoint: 425,
            settings: { slidesToShow: 1, slidesToScroll: 1, autoplay: true }
          },
          {
            breakpoint: 768,
            settings: { slidesToShow: 2, slidesToScroll: 1, autoplay: true }
          },
          {
            breakpoint: 1024,
            settings: { slidesToShow: 3, slidesToScroll: 1 }
          }
        ]
      };

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <div key={index} className="carousel-slide">
          <img src={slide.image} alt={slide.caption} />
          {/* <div className="carousel-caption">{slide.caption}</div> */}
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
