import Box from "@mui/material/Box";

const Container = ({ children }) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 2,
        pt: 10,
        overflow: "auto",
      }}
    >
      {children}
    </Box>
  );
};

export default Container;
