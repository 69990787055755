import React, { useState, useEffect } from 'react';
import Container from '../../sharedComponents/Container';
import {
  useMediaQuery,
  Modal,
  Box,
  IconButton,
  Typography,
  Button,
  Grid,
  CardContent,
  TextField,
  Tab,
  Tabs,
  MenuItem,
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import { Breadcrumbs } from '@mui/material';
import { Link } from '@mui/material';
import 'react-multi-email/dist/style.css';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserGroupMembers,
  fetchGroupTournaments,
  fetchGroup,
  exitGroup,
} from '../../redux/groups/group.actions';
import { getAllUser, addUser } from '../../redux/users/user.actions';
import { getTournaments } from '../../redux/tournaments/tournament.actions';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TournamentCard from '../../components/TournamentsCard/index';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { toast } from 'react-toastify';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#363636',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const GroupDetails = () => {
  const { group_id } = useParams();
  const user = localStorage.getItem('user_id');
  const isMobile = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch();
  const userList = useSelector(state => state.users.userList);
  const group = useSelector(state => state.groups.group);
  const groupMembers = useSelector(state => state.groups.userGroupMembers);
  const groupTournaments = useSelector(state => state.groups.groupTournaments);
  const isGroupActive = groupMembers.length >= 3;

  const activeTournaments = useSelector(
    state => state.tournaments.activeTournaments,
  );
  const upcomingTournaments = useSelector(
    state => state.tournaments.upcomingTournaments,
  );
  const [title, setTitle] = useState('Group Tournaments');
  const [anchorEl, setAnchorEl] = useState(null);
  const [memberListOpen, setMemberListOpen] = useState(false);
  const [groupTournamentsOpen, setGroupTournamentsOpen] = useState(true);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [userAdded, setUserAdded] = useState(false);
  const [removeUser, setRemoveUser] = useState(false);
  const [activeTab, setActiveTab] = useState('group-tournaments');

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleInviteModalClose = () => {
    setInviteModalOpen(false);
  };

  const fetchUserList = async () => {
    dispatch(getAllUser(group_id));
    setInviteModalOpen(true);
  };

  const handleMenuchange = filter => {
    setAnchorEl(null);
    setMemberListOpen(false);
    setGroupTournamentsOpen(false);
    const fetchData = async () => {
      if (filter === 'group-tournaments') {
        dispatch(fetchGroupTournaments(group_id));
        setGroupTournamentsOpen(true);
        setTitle('Group Tournaments');
        return;
      }

      dispatch(getTournaments(filter, group_id));

      setTitle(() => {
        if (filter === 'current') return 'Active Tournaments';
        return 'Active Tournaments';
      });
    };

    fetchData();
  };

  const handleGroupMembers = () => {
    setAnchorEl(null);
    setTitle('Group Members');
    dispatch(fetchUserGroupMembers(group_id));
    setMemberListOpen(true);
  };

  const handleSearchIconClick = () => {
    dispatch(getAllUser(group_id, searchTerm));
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    handleSearchIconClick();
  };

  const handleAddUser = async user_id => {
    if (user !== group.admin) {
      toast.warning("The logged user is not a admin of this group",  {
        position: 'top-center',
        autoClose: 5000,
      })
      return;
    }
    dispatch(addUser(group_id, user_id));
    setUserAdded(true);
  };

  const handleRemoveUser = user_id => {
    dispatch(exitGroup(group_id, user_id));
    setRemoveUser(true);
  };

  const handleSearchInputChange = e => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchGroupTournaments(group_id));
      dispatch(fetchGroup(group_id));
    };
    fetchData();
  }, [dispatch, group_id]);

  useEffect(() => {
    if (userAdded || removeUser) {
      const fetchData = async () => {
        dispatch(getAllUser(group_id, searchTerm));
        dispatch(fetchUserGroupMembers(group_id));
      };
      fetchData();
    }
    setUserAdded(false);
    setRemoveUser(false);
  }, [dispatch, userAdded, removeUser, group_id, searchTerm]);

  return (
    <>
      <Container>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          marginTop="5px"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{
                color: 'black',
                fontSize: 'larger',
                ml: { md: '60px', sm: '40px', xs: '10px' },
              }}
            >
              <Link
                underline="hover"
                key="1"
                color="inherit"
                href="/groups"
                fontSize={'bolder'}
              >
                {'› '}Manage Groups
              </Link>
              <Link
                fontSize={'bolder'}
                underline="hover"
                key="1"
                color="inherit"
                to={`/groups/${group_id}`}
              >
                {group.name}
              </Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Grid justifyContent={'center'}>
              {isMobile ? (
                <Box
                  sx={{
                    display: 'flex',
                    padding: '0 8px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Grid>
                    <Typography variant="h6" fontWeight={'700'} pl={2}>
                      {title}
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      onClick={handleMenuOpen}
                      sx={{}}
                      color="primary"
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      sx={{ top: '50px' }}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => handleMenuchange('group-tournaments')}
                      >
                        Group Tournament
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuchange('current')}>
                        Active Tournament
                      </MenuItem>
                      <MenuItem onClick={handleGroupMembers}>
                        Group members
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    //   maxWidth: { xs: 320, sm: 480 },
                    minWidth: '330px',
                    bgcolor: '#f7f5f5',
                    display: { xs: 'block', sm: 'inline-block' },
                  }}
                >
                  <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable auto tabs example"
                    centered
                  >
                    <Tab
                      onClick={() => handleMenuchange('group-tournaments')}
                      label="Group Tournaments"
                      value="group-tournaments"
                    />
                    <Tab
                      onClick={() => handleMenuchange('current')}
                      label="Active Tournaments"
                      value="active-tournaments"
                    />
                    <Tab
                      onClick={() => handleGroupMembers()}
                      label="Group Members"
                      value="group-members"
                    />
                  </Tabs>
                </Box>
              )}

              <Grid item xs={12} sx={{ position: 'relative' }} mt={5}>
                {/* {memberListOpen && (
                  <Button
                    variant="standard"
                    sx={{
                      position: "absolute",
                      left: "60%",
                      transform: "translateX(50%)",
                      marginTop: "-39px",
                    }}
                    onClick={fetchUserList}
                  >
                    Add Members
                  </Button>
                )} */}
              </Grid>
            </Grid>
            {memberListOpen ? (
              <>
              {!isGroupActive && (
                <Typography
                  variant="body2"
                  color="error"
                  align="center"
                  sx={{ marginBottom: 2 }}
                >
                  Minimum 3 members are required to make the group active
                </Typography>
              )}
                <TableContainer
                  sx={{ maxWidth: '600px', minWidth: '300px', boxShadow: '3' }}
                  component={Paper}
                >
                  <Table sx={{ width: '100%' }}>
                    <TableHead>
                      <TableRow key={'head'}>
                        <StyledTableCell
                          sx={{
                            padding: '10px 16px',
                            color: theme => theme.palette.text.secondary,
                          }}
                          align="center"
                        >
                          Name
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            padding: '10px 16px',
                            color: theme => theme.palette.text.secondary,
                          }}
                          align="center"
                        >
                          Action
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupMembers.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            No members joined in this group.
                          </TableCell>
                        </TableRow>
                      ) : (
                        groupMembers.map(members => (
                          <StyledTableRow key={members.id}>
                            <StyledTableCell
                              sx={{ padding: '10px 16px' }}
                              align="center"
                              scope="row"
                            >
                              {members.first_name} {members.last_name}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ padding: '10px 16px' }}
                              align="center"
                              scope="row"
                            >
                              {user === members.id ? (
                                <Button
                                  variant="contained"
                                  sx={{
                                    bgcolor: '#e04e4ee3',
                                    fontWeight: 550,
                                    width: '78px',
                                  }}
                                  size="small"
                                  onClick={() => handleRemoveUser()}
                                  disabled={members.id === group.admin}
                                >
                                  Exit
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  sx={{
                                    bgcolor: '#e04e4ee3',
                                    fontWeight: 550,
                                    width: '78px',
                                  }}
                                  size="small"
                                  onClick={() => handleRemoveUser(members.id)}
                                  disabled={members.id === group.admin}
                                >
                                  Remove
                                </Button>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Fab
                  color="primary"
                  aria-label="add"
                  sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                  }}
                  onClick={fetchUserList}
                >
                  <AddIcon />
                </Fab>
              </>
            ) : null}

            {!memberListOpen && groupTournamentsOpen && (
              <Grid item xs={12}>
                {groupTournaments.length > 0 ? (
                  <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    flexDirection="row"
                    flexWrap="wrap"
                    padding={2}
                  >
                    {groupTournaments.map(tournament => (
                      <Grid item>
                        <TournamentCard
                          tournament={tournament}
                          type={'group-tournament'}
                          group_id={group_id}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <CardContent>
                    <Typography variant="body1">
                      There are no tournaments currently.
                    </Typography>
                  </CardContent>
                )}
              </Grid>
            )}

            {!groupTournamentsOpen && !memberListOpen && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      marginBottom: 2,
                      fontWeight: 'bold',
                      color: 'rgba(0, 0, 0, 0.54)',
                    }}
                  >
                    Current Tournaments
                  </Typography>
                  {activeTournaments.length > 0 ? (
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      flexDirection="row"
                      flexWrap="wrap"
                      padding={2}
                    >
                      {activeTournaments.map(tournament => (
                        <Grid item>
                          <TournamentCard
                            tournament={tournament}
                            type={'group'}
                            group_id={group_id}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <CardContent>
                      <Typography variant="body1">
                        There are no tournaments currently.
                      </Typography>
                    </CardContent>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      marginBottom: 2,
                      fontWeight: 'bold',
                      color: 'rgba(0, 0, 0, 0.54)',
                    }}
                  >
                    Upcoming Tournaments
                  </Typography>
                  {upcomingTournaments.length > 0 ? (
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      flexDirection="row"
                      flexWrap="wrap"
                      padding={2}
                    >
                      {upcomingTournaments.map(tournament => (
                        <Grid item>
                          <TournamentCard
                            tournament={tournament}
                            type={'group'}
                            group_id={group_id}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <CardContent>
                      <Typography variant="body1">
                        There are no tournaments currently.
                      </Typography>
                    </CardContent>
                  )}
                </Grid>
              </>
            )}

            <Modal open={inviteModalOpen} onClose={handleInviteModalClose}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  minWidth: 300,
                  width: '500',
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                  textAlign: 'center',
                  boxSizing: 'border-box',
                }}
              >
                <IconButton
                  sx={{ position: 'absolute', top: 0, right: 0 }}
                  onClick={handleInviteModalClose}
                  color="primary"
                >
                  <CancelIcon />
                </IconButton>

                <form onSubmit={handleFormSubmit}>
                  <TextField
                    type="text"
                    placeholder="Search members"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    style={{
                      width: '100%',
                      padding: '1rem',
                      marginBottom: '1rem',
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button onClick={handleSearchIconClick}>
                            <SearchIcon />
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
                <div style={{ height: '400px', overflowY: 'auto' }}>
                  <TableContainer sx={{ width: '100%' }} component={Paper}>
                    <Table sx={{ width: '100%' }}>
                      <TableHead>
                        <TableRow key={'head'}>
                          <TableCell
                            sx={{
                              padding: '10px 16px',
                              color: theme => theme.palette.text.secondary,
                            }}
                            align="left"
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '10px 16px',
                              color: theme => theme.palette.text.secondary,
                            }}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userList.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              No users available
                            </TableCell>
                          </TableRow>
                        ) : (
                          userList.map(user => (
                            <TableRow key={user.id}>
                              <TableCell
                                sx={{ padding: '10px 16px' }}
                                align="left"
                                scope="row"
                              >
                                {`${user.first_name} ${user.last_name}`}
                              </TableCell>
                              {user.is_member ? (
                                <TableCell
                                  sx={{ padding: '10px 16px' }}
                                  align="right"
                                  scope="row"
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled
                                  >
                                    Added
                                  </Button>
                                </TableCell>
                              ) : (
                                <TableCell
                                  sx={{ padding: '10px 16px' }}
                                  align="right"
                                  scope="row"
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleAddUser(user.id)}
                                  >
                                    Add
                                  </Button>
                                </TableCell>
                              )}
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default GroupDetails;
