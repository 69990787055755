import QuestionActionType from "./question.type";

export const getQuestions = (sport_id) => {
    return {
        type: QuestionActionType.GET_QUESTION,
        sport_id,
    };
};

export const setQuestions = (payload) => {
    return {
        type: QuestionActionType.SET_QUESTION,
        payload,
    };
};

export const getMainOptions = (match_id) => {
    return {
        type: QuestionActionType.GET_MAIN_OPTIONS,
        match_id,
    };
};

export const setMainOptions = (payload) => {
    return {
        type: QuestionActionType.SET_MAIN_OPTIONS,
        payload,
    };
};

export const getPlayers = (match_id) => {
    return {
        type: QuestionActionType.GET_PLAYERS,
        match_id,
    };
};

export const setPlayers = (payload) => {
    return {
        type: QuestionActionType.SET_PLAYERS,
        payload,
    };
};

export const addAnswers = (answerObj, callback = () => {}) => {
    return {
        type: QuestionActionType.ADD_ANSWERS,
        answerObj,
        callback,
    };
};

export const getAnswers = (matchId, callback = () => {}, userId) => {
    return {
        type: QuestionActionType.GET_ANSWERS,
        matchId,
        callback,
        userId,
    };
};

export const setAnswers = (payload) => {
    return {
        type: QuestionActionType.SET_ANSWERS,
        payload,
    };
};
