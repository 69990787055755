const GroupActionType = {
  FETCH_GROUPS: 'FETCH_GROUPS',
  SET_GROUPS: 'SET_GROUPS',
  FETCH_USER_GROUPS: 'FETCH_USER_GROUPS',
  SET_USER_GROUPS: 'SET_USER_GROUPS',
  FETCH_USER_GROUP_MEMEBERS: 'FETCH_USER_GROUP_MEMEBERS',
  ADD_GROUP: 'ADD_GROUP',
  EXIT_GROUP: 'EXIT_GROUP',
  SET_USER_GROUP_MEMBERS: 'SET_USER_GROUP_MEMBERS',
  FETCH_GROUP_TOURNAMENTS: 'FETCH_GROUP_TOURNAMENTS',
  SET_GROUP_TOURNAMENTS: 'SET_GROUP_TOURNAMENTS',
  UPDATE_GROUP_ACTIVE_STATUS: 'UPDATE_GROUP_ACTIVE_STATUS'
};

export default GroupActionType;
