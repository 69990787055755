import UserActionType from "./user.types"

export const getAllUser = (group_id, searchTerm = null) => {
    return {
        type: UserActionType.GET_ALL_USER,
        group_id,
        searchTerm
    }
}

export const setAllUser = (user) => {
    return {
        type: UserActionType.SET_ALL_USER,
        user
    }
}

export const addUser = (group_id, user_id) => {
    return {
        type: UserActionType.ADD_USER,
        user_id,
        group_id
    }
}