const MatchActionType = {
    GET_MATCHES: "GET_MATCHES",
    SET_MATCHES: "SET_MATCHES",
    SET_MATCH_DETAILS: "SET_MATCH_DETAILS",
    GET_USER_MATCHES: "GET_USER_MATCHES",
    SET_USER_MATCHES: "SET_USER_MATCHES",
    GET_MATCH_DETAILS: "GET_MATCH_DETAILS",
    GET_MATCH_POINTS: "GET_MATCH_POINTS",
}

export default MatchActionType;
