import React, { useEffect } from "react";
import { Typography, Grid, CardContent } from "@mui/material";
import Container from "../../sharedComponents/Container";
import { fetchAllTournaments } from "../../redux/tournaments/tournament.actions";
import { getUserMatches } from '../../redux/matches/match.actions'
import { useDispatch, useSelector } from "react-redux"
import TournamentCard from "../../components/TournamentsCard/index"
import MatchCard from "../../components/MatchCard";
import Carousel from "../../components/Carousel";
import img from '../../assets/sponsers/IMG.jpg';
import img1 from '../../assets/sponsers/img1.jpg';
import img2 from '../../assets/sponsers/img2.jpg';
import img3 from '../../assets/sponsers/img3.jpg';
import img4 from '../../assets/sponsers/img4.jpg';
import img5 from '../../assets/sponsers/img5.jpg';
import img6 from '../../assets/sponsers/img6.jpg';
import img7 from '../../assets/sponsers/img7.jpg';

const Dashboard = () => {
  const dispatch = useDispatch();
  const activeTournaments = useSelector(state => state.tournaments.activeTournaments);
  const upcomingTournaments = useSelector(state => state.tournaments.upcomingTournaments);
  const userMatches = useSelector(state => state.matches.userMatches);
  const user = useSelector((state) => state.auth.userDetails);

  const slides = [
    {
      image: img,
      caption: 'Slide 1',
    },
    {
      image: img2,
      caption: 'Slide 2',
    },
    {
      image: img1,
      caption: 'Slide 1',
    },
    // {
    //   image: img3,
    //   caption: 'Slide 3',
    // },
    // {
    //   image: img4,
    //   caption: 'Slide 3',
    // },
    // {
    //   image: img5,
    //   caption: 'Slide 3',
    // },
    // {
    //   image: img6,
    //   caption: 'Slide 3',
    // },
    // {
    //   image: img7,
    //   caption: 'Slide 3',
    // },
  ];

  useEffect(() => {
    user && dispatch(fetchAllTournaments());
  }, [dispatch, user]);

  useEffect(() => {
    user && dispatch(getUserMatches());
  }, [dispatch, user]);

  useEffect(() => {
    localStorage.removeItem("tournament_name");
    localStorage.removeItem("tournament_id");
  });

  return (
    <Container>
      <Grid container spacing={1} justifyContent="center" marginTop="2px" alignItems='center'>
        <Grid
          sx={{
            width: '100%',
            height: 'auto',
          }}
        >
          <Carousel slides={slides} />
        </Grid>
        <Grid item xs={12} mt={5}>
          {userMatches && userMatches.length > 0 && (
            <>
              <Typography variant="h5" component="div" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                Active Matches
              </Typography>

              <Grid container spacing={3} sx={{ padding: 0 }} justifyContent="center" flexDirection='row' flexWrap='wrap' padding={2} >
                {userMatches.slice(0, 9).map((match) => (
                  <Grid sx={{ paddingLeft: 0 }} item>
                    <MatchCard match={match} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}

        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div" sx={{ marginTop: 2, fontWeight: 'bold' }}>
            Current Tournaments
          </Typography>
          {activeTournaments.length > 0 ? (
            <Grid container spacing={3} justifyContent="center" flexDirection='row' flexWrap='wrap' padding={2} >
              {activeTournaments.map((tournament) => (
                <Grid item>
                  <TournamentCard tournament={tournament} type={'user'} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <CardContent>
              <Typography variant="body1">There are no tournaments currently.</Typography>
            </CardContent>
          )}

        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="div" sx={{ marginTop: 2, fontWeight: 'bold' }}>
            Upcoming Tournaments
          </Typography>
          {upcomingTournaments.length > 0 ? (
            <Grid container spacing={3} justifyContent="center" flexDirection='row' flexWrap='wrap' padding={2} >
              {upcomingTournaments.map((tournament) => (
                <Grid item>
                  <TournamentCard tournament={tournament} type={'user'} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <CardContent>
              <Typography variant="body1">There are no tournaments currently.</Typography>
            </CardContent>
          )}

        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
