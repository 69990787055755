import MatchActionType from "./match.types"

const INITIAL_STATE = {
    matches: [],
}

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case MatchActionType.SET_MATCHES:
            return {
                ...state,
                matches: action.payload,
            };
        case MatchActionType.SET_MATCH_DETAILS:
            return {
                ...state,
                matchDetails: action.payload,
            };
        case MatchActionType.SET_USER_MATCHES:
            return {
                ...state,
                userMatches: action.payload,
            }
        default:
            return state
    }
}

export default reducer;
