import AuthActionType from "./auth.types"

export const login = (email, password, callback = () => {}) => {
    return {
        type: AuthActionType.LOGIN,
        email,
        password,
        callback,
    }
}

export const setToken = (token) => {
    return {
        type: AuthActionType.SET_TOKEN,
        token,
    }
}

export const logout = () => {
    return {
        type: AuthActionType.LOGOUT
    }
}

export const signup = (userData) => ({
    type: AuthActionType.SIGNUP,
    payload: userData,
});

export const getUser = (onError=()=>{}) => {
    return {
        type: AuthActionType.GET_USER,
        onError
    }
}

export const setUser = (user) => {
    return {
        type: AuthActionType.SET_USER,
        user
    }
}

export const getUserDetails = () => {
    return {
        type: AuthActionType.GET_USER_DETAILS,
    }
}

export const setUserDetails = (payload) => {
    return {
        type: AuthActionType.SET_USER_DETAILS,
        payload,
    }
}

export const updatePassword = (newPassword, confirmPassword) => {
    return {
        type: AuthActionType.UPDATE_PASSWORD,
        newPassword, 
        confirmPassword,
    }
}

export const updateProfile = (users) => {
    return {
        type: AuthActionType.UPDATE_PROFILE,
        users,
    }
}

export const forgotPassword = (email) => {
    return {
        type: AuthActionType.FORGOT_PASSWORD,
        email, 
        
    }
}

export const resetPassword = (newPassword, confirmPassword, uid, token) => {
    return {
        type: AuthActionType.RESET_PASSWORD,
        newPassword, 
        confirmPassword,
        uid,
        token,
    }
}
export const changePassword = () => {
    return {
        type: AuthActionType.CHANGE_PASSWORD,
    }
}

export const updateTerms = (accept) => {
    return {
        type: AuthActionType.TERMS_AND_CONDITION,
        accept,
    }
}
