import { jwtDecode } from 'jwt-decode';

export const IsUserLoggedIn = ({
  apiToken = localStorage.getItem('token'),
}) => {
  if (
    apiToken !== undefined &&
    typeof apiToken === 'string' &&
    apiToken.trim().length > 0
  ) {
    const decoded = jwtDecode(apiToken) || {};
    if (decoded?.exp && decoded.exp > Math.floor(Date.now() / 1000)) {
      return true;
    }
  }
  return false;
};
