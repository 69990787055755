import { takeEvery, put } from "redux-saga/effects"
import { POST, GET, PATCH, PUT } from "../../axios";
import AuthActionType from "./auth.types"
import * as AuthActions from "./auth.actions"
import { setError, setAccountCreated } from "../actions/action.actions"
import urls from "../urls"
import { toast } from "react-toastify";

function* loginSaga({email, password, callback}) {    
    const data = yield POST(urls.login, { email, password })
    if (data && data.access_token){
        yield put(AuthActions.setToken(data.access_token));
        sessionStorage.setItem("user_id", data.user_id);
        localStorage.setItem('terms', data.terms_confirmed)
        yield put(AuthActions.setToken(data.access_token));
        yield put (AuthActions.getUser());
        callback();
    } else {
        let errorMessage = 'An error occurred during signin. Please try again later.';
        if (data.detail) {
            errorMessage = data.detail
        }
        yield put(setError(errorMessage))
    }
}

function* signupSaga(action) {
    try {
        const response = yield POST(urls.signup, action.payload);
        if (response.success === true) {
            toast.success("Your Account is created. Sign in now", {
                position: "top-center",
                autoClose: 5000,
            });

            yield put(setAccountCreated("success"));
        } else {
            let errorMessage = "An error occurred during signup. Please try again later.";

            if ((response.success === "False" || response.success === false) && response.message) {
                errorMessage = response.message;
            }

            yield put(setError(errorMessage));
        }
    } catch (error) {
        console.error("Error during signup:", error);
        yield put(setError("An unexpected error occurred."));
    }
}

function* getUserSaga({onError}) {
    let data = yield GET(
        urls.users_details
    )
    if (data && data.data){
        localStorage.setItem("user_id", data.data.id)
        yield put(AuthActions.setUser(data.data))
    } else {
        onError()
    }
}

function* getUserDetailsSaga() {
    try {
    const data = yield GET(
        urls.users_details
    );
    if (data && data.data){
        yield put(AuthActions.setUserDetails(data.data))
    } else {
        let errorMessage = "User details not found!.";
        if (data.detail) {
          errorMessage = data.detail;
        }
        yield put(setError(errorMessage));
      }
    } catch (error) {
      console.error("Error during user details:", error);
      yield put(setError("An unexpected error occurred."));
    }
}

function* updatePasswordSaga(action) {
    try {
        const { newPassword, confirmPassword } = action;

        const data = yield PATCH("updatepassword/", {
          new_password1: newPassword ,
          new_password2: confirmPassword,
        });
        if (data?.success === "True") {
            toast.success("Password updated successfully", {
            position: "top-center",
            autoClose: 5000,
        });
        } else {
            let errorMessage = "An error occurred during updating password. Please try again later.";
            if (data.message || data?.success === false || data?.non_field_errors ) {
            errorMessage = data.message || data.non_field_errors.join('\n');
        }
        yield put(setError(errorMessage));
      }
    } catch (error) {
      console.error("Error during group creating group:", error);
      yield put(setError("An unexpected error occurred."));
    }
  }
  
function* forgotPasswordSaga(action) {
    try {
        const response = yield POST(urls.forgot_password,{
            email: action.email
        });
        if (response.success === true) {
            toast.success("Email sent to your registered email to reset the password", {
                position: "top-center",
                autoClose: 5000,
            });
        } else {
            let errorMessage = "An error occurred . Please try again later.";

            if ((response.success === "False" || response.success === false) && response.message) {
                errorMessage = response.message;
            }

            yield put(setError(errorMessage));
        }
    } catch (error) {
        console.error("Error occured:", error);
        yield put(setError("An unexpected error occurred."));
    }
}

function* resetPasswordSaga(input) {
    try {
        //  const userId = input.user_id
         const data = yield PATCH(`resetpassword/${input.uid}/${input.token}/`, {
          new_password: input.newPassword ,
          confirm_password: input.confirmPassword,
          });
        if (data?.success === "True") {
            toast.success("Reset the Password successfully", {
            position: "top-center",
            autoClose: 5000,
        });
        } else {
            let errorMessage = "An error occurred during reset the password. Please try again later.";
            if (data.message || data?.success === false || data?.non_field_errors ) {
            errorMessage = data.message || data.non_field_errors.join('\n');
        }
        yield put(setError(errorMessage));
      }
    } catch (error) {
      console.error("Error during reset the password:", error);
      yield put(setError("An unexpected error occurred."));
    }
  }
  
function* changePasswordSaga() {
    try {
    const data = yield GET(
        urls.change_password
    );
    if (data && data.data){
        yield put(AuthActions.setUserDetails(data.data))
    } else {
        let errorMessage = "User details not found!.";
        if (data.detail) {
          errorMessage = data.detail;
        }
        yield put(setError(errorMessage));
      }
    } catch (error) {
      console.error("Error during user details:", error);
      yield put(setError("An unexpected error occurred."));
    }
}

  function* updateProfileSaga(action) {
    try {
        const { first_name, last_name } = action.users;
        const user_id = localStorage.getItem('user_id')
        const data = yield PUT(`users/${user_id}/`, {
          first_name: first_name,
          last_name: last_name,
        });
        
        if (data?.success === true) {
            toast.success("Profile updated successfully", {
            position: "top-center",
            autoClose: 5000,
        });
        } else {
            let errorMessage = "An error occurred during updating Profile. Please try again later.";
            if (data.message || data?.success === false || data?.non_field_errors ) {
            errorMessage = data.message || data.non_field_errors.join('\n');
        }
        yield put(setError(errorMessage));
      }
    } catch (error) {
      console.error("Error during group creating group:", error);
      yield put(setError("An unexpected error occurred."));
    }
  }

  function* updateTermsSaga(action) {
    try {
        const data = yield PATCH("/terms/", {
          terms_and_conditions: action.accept
        });
        if (data?.success === true) {
            toast.success("Terms and Condtion accepted", {
            position: "top-center",
            autoClose: 5000,
        });
        } else {
            let errorMessage = "An error occurred during updating password. Please try again later.";
            if (data.message || data?.success === false || data?.non_field_errors ) {
            errorMessage = data.message || data.non_field_errors.join('\n');
        }
        yield put(setError(errorMessage));
      }
    } catch (error) {
      console.error("Error during group creating group:", error);
      yield put(setError("An unexpected error occurred."));
    }
  }

export function* authSagas() {
    yield takeEvery(AuthActionType.LOGIN, loginSaga)
    yield takeEvery(AuthActionType.SIGNUP, signupSaga)
    yield takeEvery(AuthActionType.GET_USER, getUserSaga)
    yield takeEvery(AuthActionType.GET_USER_DETAILS, getUserDetailsSaga)
    yield takeEvery(AuthActionType.UPDATE_PASSWORD, updatePasswordSaga)
    yield takeEvery(AuthActionType.UPDATE_PROFILE, updateProfileSaga)
    yield takeEvery(AuthActionType.RESET_PASSWORD, resetPasswordSaga)
    yield takeEvery(AuthActionType.FORGOT_PASSWORD, forgotPasswordSaga)
    yield takeEvery(AuthActionType.CHANGE_PASSWORD, changePasswordSaga)
    yield takeEvery(AuthActionType.TERMS_AND_CONDITION, updateTermsSaga)
}
