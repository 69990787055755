import React from 'react';
import { Card, CardMedia, Typography, Box, Grid } from '@mui/material';

import LSGoutline from '../../assets/matches/LSGoutline.png';
import CSKoutline from '../../assets/matches/CSKoutline.png';
import DCoutline from '../../assets/matches/DCoutline.png';
import GToutline from '../../assets/matches/GToutline.png';
import KKRoutline from '../../assets/matches/KKRoutline.png';
import MIoutline from '../../assets/matches/MIoutline.png';
import PBKSoutline from '../../assets/matches/PBKSoutline.png';
import RCBoutline from '../../assets/matches/RCBoutline.png';
import RRoutline from '../../assets/matches/RRoutline.png';
import SRHoutline from '../../assets/matches/SRHoutline.png';
import defaultLogo from '../../assets/matches/default-logo.png';
import vs from '../../assets/matches/vsimg.png';

const assets = {
  CSK: CSKoutline,
  RCB: RCBoutline,
  LSG: LSGoutline,
  DC: DCoutline,
  GT: GToutline,
  KKR: KKRoutline,
  MI: MIoutline,
  PBKS: PBKSoutline,
  RR: RRoutline,
  SRH: SRHoutline,
};

const TeamCard = ({
  selected = false,
  handleCardClick = () => {},
  shortName,
  team,
  fullName,
  logo,
  question,
  disabled = false,
}) => {
  return (
    <Card
      sx={{
        boxShadow: disabled ? 'none' : '0 0 23px #aaa',
        marginBottom: '3px',
        cursor: disabled ? 'default' : 'pointer',
        border: selected ? '1px solid green' : 'none',
        backgroundColor: disabled ? '#eee' : selected ? '#cbcbcb' : '#f4f4f4',
        minWidth: '200px',
        paddingTop: '10px',
        minHeight: '120px'
      }}
      onClick={() => (disabled ? () => {} : handleCardClick(team, question))}
    >
      <CardMedia
        component="img"
        image={(logo !== "nan" && logo ) ? logo : assets[shortName] || defaultLogo}
        sx={{
          // height: '80px',
          width: '130px',
          margin: '10px 40px',
        }}
      />

      <Typography variant="h6" fontWeight={'bold'}>
        {fullName}
      </Typography>
    </Card>
  );
};

const MainQuestion = ({
  question,
  handleCardClick,
  options,
  selectedCard,
  disabled,
}) => {
  return (
    <Box
      sx={{
        maxWidth: '100%',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        alignItems: 'center',
        justifyContent: 'center',
        mt: 3,
        flexWrap: 'wrap',
      }}
    >
      <Grid sx={{ display: 'block' }}>
      <Typography variant='h6' component={'h6'} sx={{ fontWeight:700, mb:1}}>Team A</Typography>
      <TeamCard
        handleCardClick={handleCardClick}
        question={question}
        selected={selectedCard === options?.team_details?.team1}
        shortName={options?.team_details?.team1_short_name}
        fullName={options?.team_details?.team1}
        logo={options?.team_details?.team1_logo}
        team={options?.team_details?.team1}
        disabled={disabled}
      />
      </Grid>
      <img
        src={vs}
        alt="vsimage"
        style={{
          height: '60px',
          width: '60px',
          alignSelf: 'center',
          margin: '0 20px',
        }}
      />
      <Grid sx={{ display: 'block' }}>
      <Typography variant='h6' component={'h6'} sx={{ fontWeight:700, mb:1}}>Team B</Typography>
      <TeamCard
        handleCardClick={handleCardClick}
        question={question}
        selected={selectedCard === options?.team_details?.team2}
        shortName={options?.team_details?.team2_short_name}
        fullName={options?.team_details?.team2}
        logo={options?.team_details?.team2_logo}
        team={options?.team_details?.team2}
        disabled={disabled}
      />
      </Grid>
    </Box>
  );
};

export default MainQuestion;
