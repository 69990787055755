import { takeEvery, put } from "redux-saga/effects"
import { POST, GET } from "../../axios"
import UserActionType from "./user.types"
import * as UserActions from "./user.actions"
import { setError } from "../actions/action.actions";
import urls from "../urls"
import { toast } from "react-toastify";
import { fetchUserGroupMembers  } from "../../redux/groups/group.actions";

function* getAllUserSaga(inputs) {
    let group_id = inputs.group_id
    let search_term = inputs.searchTerm
    let url = `${urls.users}?group_id=${group_id}`

    if (search_term) {
        url = `${url}&status=Accept&search_term=${search_term}`
    }

    let data = yield GET(
        url
    )
    if (data && data.data) {
        yield put(UserActions.setAllUser(data.data))
    }
}

function* addUserSaga(input) {
    try {
        const data = yield POST(`${urls.groups}${input.group_id}/invite_user/`,
            {
                user_id: input.user_id,
            });

        if (data && data.success === true) {
            toast.success("User added to the Group successfully", {
                position: "top-center",
                autoClose: 5000,
            });
            yield put(UserActions.getAllUser(input.group_id, ""))
            yield put(fetchUserGroupMembers(input.group_id))
        } else {
            let errorMessage = "An error occurred during adding user to the group. Please try again later.";
            if (data.message) {
                errorMessage = data.message
            }
            yield put(setError(errorMessage));
        }
    } catch (error) {
        console.error("Error during adding user to the group:", error);
        yield put(setError("An unexpected error occurred."));
    }
}

export function* userSaga() {
    yield takeEvery(UserActionType.GET_ALL_USER, getAllUserSaga)
    yield takeEvery(UserActionType.ADD_USER, addUserSaga)
}
