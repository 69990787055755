import ActionActionTypes from "./action.types"

const INITIAL_STATE = {
    error: null,
    success: false,
    accountCreated: null,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ActionActionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case ActionActionTypes.SET_SUCCESS:
            return {
                ...state,
                success: action.payload
            }
        case ActionActionTypes.SET_ACCOUNT_CREATED:
            return {
                ...state,
                accountCreated: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;
