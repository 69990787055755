import { createStore, applyMiddleware } from "redux";
import rootReducer from "./root.reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { authSagas } from "./auth/auth.saga";
import { userSaga } from "./users/user.saga";
import { tournamentSagas } from "./tournaments/tournament.saga";
import { groupSaga } from "./groups/group.saga";
import { matchSagas } from "./matches/match.saga"
import { questionSagas } from "./questions/question.saga";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(authSagas);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(tournamentSagas);
sagaMiddleware.run(groupSaga);
sagaMiddleware.run(matchSagas);
sagaMiddleware.run(questionSagas);


export default store;
