const AuthActionType = {
    LOGIN: "LOGIN",
    SET_TOKEN: "SET_TOKEN",
    LOGOUT: "LOGOUT",
    SIGNUP: "SIGNUP",
    GET_USER: "GET_USER",
    SET_USER: "SET_USER",
    GET_USER_DETAILS: "GET_USER_DETAILS",
    SET_USER_DETAILS: "SET_USER_DETAILS",
    UPDATE_PASSWORD: "UPDATE_PASSWORD",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    RESET_PASSWORD:"RESET_PASSWORD",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    TERMS_AND_CONDITION: "TERMS_AND_CONDITION"
}

export default AuthActionType
