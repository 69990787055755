import React, { useState, useEffect } from 'react';
import Container from '../../sharedComponents/Container';
import {
  Modal,
  Box,
  IconButton,
  Typography,
  Button,
  Fab,
  TextField,
  Grid,
  Breadcrumbs,
  Link,
  Tab,
  Tabs,
  TableHead,
  useMediaQuery,
} from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserGroups,
  addGroup,
  updateGroupActiveStatus,
} from '../../redux/groups/group.actions';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#363636',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Groups = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userGroupDetails = useSelector(state => state.groups.userGroups);
  const [openModal, setOpenModal] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [hoveredGroup, setHoveredGroup] = useState(null);
  const [activeTab, setActiveTab] = useState('Groups');
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleViewClick = group => {
    localStorage.setItem('group_name', group.name);
    navigate(`/groups/${group.id}`);
  };

  const handleNameChange = event => {
    setGroupName(event.target.value);
  };

  const handleAddGroup = async () => {
    try {
      dispatch(addGroup(groupName));
      setGroupName('');
    } catch (error) {
      console.error('Error during creating groups:', error);
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    dispatch(getUserGroups());
  }, [dispatch]);

  const handleToggleStatus = group => {
    const newStatus = !group.active;
    dispatch(updateGroupActiveStatus(group.id, newStatus));
  };

  return (
    <>
      <Container>
        <Grid item xs={12} marginTop="10px">
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            sx={{
              color: 'black',
              fontSize: 'larger',
              ml: { md: '60px', sm: '40px', xs: '10px' },
            }}
          >
            <Link
              underline="hover"
              key="1"
              color="inherit"
              href="/groups"
              fontSize={'bolder'}
            >
              {'› '}Manage Groups
            </Link>
          </Breadcrumbs>
        </Grid>
        <Box
          sx={{
            width: '100%',
            maxWidth: '700px',
          }}
        >
          <Tabs
            value={activeTab}
            centered
            color="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            sx={{ mb: 2 }}
          >
            <Tab label="Groups" value={'Groups'} />
          </Tabs>

          <TableContainer
            sx={{ maxWidth: '600px', minWidth: '300px', boxShadow: '3' }}
            component={Paper}
          >
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow key={'head'}>
                  <StyledTableCell
                    sx={{
                      padding: '10px 16px',
                      color: theme => theme.palette.text.secondary,
                      width: '50%',
                    }}
                    align="center"
                  >
                    Name
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: '10px 16px',
                      color: theme => theme.palette.text.secondary,
                      width: '50%',
                    }}
                    align="center"
                  >
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userGroupDetails.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography variant="subtitle1">
                        No groups linked with the user.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  userGroupDetails.map(group => (
                    <StyledTableRow
                      key={group.id}
                      onMouseEnter={() => setHoveredGroup(group)}
                      onMouseLeave={() => setHoveredGroup(null)}
                    >
                      <StyledTableCell
                        sx={{ padding: '10px 16px' }}
                        align="center"
                        scope="row"
                        title={group.active ? 'Active' : 'Inactive'}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography sx={{ cursor: 'pointer' }}>
                            {group.name}
                          </Typography>
                          {!group.active && (
                            <Box
                              component="span"
                              sx={{
                                marginLeft: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <VisibilityOffIcon
                                fontSize="small"
                                color="error"
                              />
                            </Box>
                          )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ padding: '10px 16px' }}
                        align="center"
                        scope="row"
                      >
                        <Button
                          variant="contained"
                          sx={{
                            fontSize: '0.65rem',
                            padding: '6px 12px',
                            width: '80px',
                          }}
                          onClick={() => handleViewClick(group)}
                        >
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: 'fixed',
              bottom: isMobile ? 8 : 16,
              right: isMobile ? 8 : 16,
              width: isMobile ? 45 : 56,
              height: isMobile ? 45 : 56, 
            }}
            onClick={() => setOpenModal(true)}
          >
            <AddIcon  sx={{ fontSize: isMobile ? 20 : 24 }}/>
          </Fab>
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              minWidth: 300,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              textAlign: 'center',
            }}
          >
            <IconButton
              sx={{ position: 'absolute', top: 0, right: 0 }}
              onClick={handleCloseModal}
              color="primary"
            >
              <CancelIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{ marginBottom: 2, fontWeight: 'bold' }}
            >
              Add Group
            </Typography>
            <TextField
              label="Group Name"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              value={groupName}
              onChange={handleNameChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddGroup}
              disabled={!groupName}
            >
              Submit
            </Button>
          </Box>
        </Modal>
      </Container>
    </>
  );
};

export default Groups;
