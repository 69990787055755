import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import GroupsIcon from "@mui/icons-material/Groups";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth/auth.actions";
import { Grid, Menu, MenuItem } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import GavelIcon from '@mui/icons-material/Gavel';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { QuestionAnswer, SportsCricket } from "@mui/icons-material";
import { fetchAllTournaments } from "../../redux/tournaments/tournament.actions";

const drawerWidth = 285;

const nav1 = [
  {
    name: "DashBoard",
    route: "/dashboard",
    icon: <DashboardIcon />,
  }
];

const nav2 = [
  {
    name: "Manage Groups",
    route: "/groups",
    icon: <GroupsIcon />,
  },
  {
    name: "Prizes",
    route: "/prizes",
    icon: <CardGiftcardIcon />,
  },
  {
    name: "Rules",
    route: "/rules",
    icon: <GavelIcon />,
  },
  {
    name: "FAQs",
    route: "/FAQ",
    icon: <QuestionAnswer />,
  },
]

const contactNavs = [
  {
    name: "contact@mindreams.com",
    icon: <EmailIcon />,
    route: "mailto:contact@mindreams.com"
  },
  {
    name: "\u00A9 Mindreams Infotech Pvt. Ltd.",
  }
];


export default function ResponsiveDrawer(props) {
  // const { window } = props;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activeTournaments = useSelector(state => state.tournaments.activeTournaments);

  React.useEffect(() => {
    dispatch(fetchAllTournaments());
  }, [dispatch]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = async () => {
    dispatch(logout());
  };

  const handleProfilePage = () => {
    navigate("/profile")
    handleClose()
  };

  const handleProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Grid>
      <Box sx={{display: "flex", flexDirection: "column" }}>
        <List sx={{ mt: 8 }}>
          {nav1.map((item) => (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                selected={location.pathname.includes(item.route)}
                onClick={() => {
                  navigate(item.route);
                  handleDrawerToggle();
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem  disablePadding >
              <ListItemButton
                sx={{ cursor: 'auto' }}
              >
                <ListItemIcon><SportsCricket/></ListItemIcon>
                <ListItemText primary={"Tournaments"} />
              </ListItemButton>
            </ListItem>
            {activeTournaments.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  localStorage.setItem("tournament_name", item.name)
                  localStorage.setItem("tournament_id", item.id)
                  navigate(`/tournaments/${item.id}`);
                  handleDrawerToggle();
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
            ))}
        </List>
        {nav2.map((item) => (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                selected={location.pathname.includes(item.route)}
                onClick={() => {
                  navigate(item.route);
                  handleDrawerToggle();
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
      </Box>
      <Box 
        sx={{  
          display: "flex", 
          flexDirection: "column",
          position: 'absolute',
          bottom: 0,
        }}>
        <List >
          {contactNavs.map((item) => (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                selected={false}
                component="a"
                href={item.route}
                target="_blank"
                rel="noreferrer"
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid sx={{ display: 'flex', gap: { sm: 1, md: 5 }, textAlign: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "block" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              <Link
                to="/dashboard"
                style={{ textDecoration: "none", color: "inherit", lineHeight: '2.6' }}
              >
                Predict The Winner
              </Link>
            </Typography>
          </Grid>
          <Grid sx={{ gap: 3, display: "flex" }}>
            <Typography variant="h7" noWrap component="div">
              <Link
                // profile change later
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                  fontSize: "large",
                  lineHeight: '2.6',
                }}
                onClick={handleProfile}
              >
                <Box sx={{ writingMode: 'vertical-rl' }}>
                  <AccountCircleIcon />
                </Box>
                <Typography variant="h7" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
                  Profile
                </Typography>
              </Link>
            </Typography>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{ top: '50px', width: '140px' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleProfilePage}>My Account</MenuItem>
              <MenuItem color="inherit" onClick={handleLogout} size="small">Logout</MenuItem>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Divider />
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          sx={{
            display: 'block',
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
