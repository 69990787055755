import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  CardHeader,
  CardContent,
  TextField,
  Card,
  Grid,
  Button,
  Container,
 InputLabel,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { forgotPassword } from '../../redux/auth/auth.actions';
import { theme } from '../../utils/theme';

const InputField = styled(TextField)`
  border-radius: 8px;
  margin-top: 8px;
  color: ${theme.palette.primary.contrastText},
  margin-bottom: 16px;
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &:hover fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-focused fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-error fieldset {
      border-color: #your-error-color; /* Error state */
    }
  }
`;

const ForgotPassword = props => {
  //   const theme = useTheme();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    email: '',
});

 
  //const handleSignin = async e => {
  const handleEmail = async e => {
    e.preventDefault();
    if (!values.email) {
      toast.warning('Email  is required.', {
        position: 'top-center',
        autoClose: 5000,
      });
      return;
    }

    try {
      dispatch(forgotPassword(values.email));
    } catch (error) {
      console.error('Somethig went wrong:', error);
    }
  };

  const handleChange = prop => e => {
    setValues({ ...values, [prop]: e.target.value });
  };

  

  return (
    <>
      <Container
        sx={{
          height: '100vh',
          padding: '0!important',
        }}
        maxWidth={'100vw'}
      >
        <Grid container>
          <Grid item xs={0} sm={6}>
            <div
              className="landing-image"
              style={{
                width: '100%',
                height: '100vh',
                backgroundImage: `url('https://md-sports.s3.ap-south-1.amazonaws.com/background.jpeg')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: '0.9',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            ></div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              backgroundImage: {
                xs: `url('https://md-sports.s3.ap-south-1.amazonaws.com/background.jpeg')`,
                sm: 'none',
              },
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card
              variant="outlined"
              sx={{
                width: { xs: '100vw', sm: '50vw' },
                height: '100vh',
                opacity: '0.8',
                border: 'none',
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <CardHeader
                sx={{ textAlign: 'center' }}
                title="  Forgot Password"
              />
              <CardContent>
                <form
                  //onSubmit={e => handleSignin(e)}
                onSubmit={e => handleEmail(e)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: 500,
                  }}
                 >
                  <InputLabel htmlFor="email" sx={{ alignSelf: 'flex-start' }}>
                    Email *
                  </InputLabel>
                  <InputField
                    id="email"
                    type="email"
                    fullWidth
                    margin="normal"
                    onChange={handleChange('email')}
                    value={values.email}
                  />

                 
                  <Grid item sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                  }}>
                    
                 
                    <Button
                      id="submit"
                      type="submit"
                      value="Submit"
                      variant="contained"
                      sx={{ marginTop: 4, alignContent:'center' }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ForgotPassword;
