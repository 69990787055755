import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import Container from '../../sharedComponents/Container';

const RulesPage = () => {
  const terms = [
    'Coins ➜ Units to spend for playing matches.',
    'Points ➜ Units indicating your earnings by playing the matches. The rankings will be decided based on the points.',
  ];

  const rules = [
    {
      content: 'Each user will be allotted with 5000 coins for each tournament.',
    },
    {
      content: 'Matches scheduled for the next 3 days are considered active matches. You can start playing any active matches.',
    },
    {
      content: 'To play an active match, you have to:',
      subContent: [
        'Predict the winner.',
        'Select coins (Allowed per match: 100 to 1000).',
        'If your tournament coins are less than 1000, your coins value becomes the maximum. Eg: Assume a user has 500 tournament coins, the user will be allowed to select between 100 and 500.',
      ],
    },
    {
      content: 'Each match will have 3 optional questions. Each correct prediction will earn you an extra 500 points per question.',
    },
    {
      content: 'If both mandatory and optional questions are correct, the user will get 2000 extra points.',
    },
    {
      content: 'For playing daily, you will earn 100 extra coins.',
    },
    {
      content: 'Group points are calculated based on the average points of the total users in the group.',
    },
    {
      content: 'If the match is cancelled, the user will get their bidding amount returned.'
    },
    {
      content: 'If the match is a tie for the main question (i.e., which team will win), you will not get the bidding amount back. However, if your answers to the sub-questions are correct, you will still earn the points.'
    },
  
    {
      content: 'Coins:',
      subContent: [
        'Users receive 5000 coins when they join a tournament.',
        'If a user plays daily, they will earn 100 coins.',
        'Whenever a user plays a match, the bidding amount will be deducted from their coins before the match.',
        'If a user wins a match, they will get the bidding amount as coins. Eg:Coins = coins + selected points.',
      ],
    },
    {
      content: 'Points:',
      subContent: [
        'If a user bids for x points and wins the match, the bidding amount will be added to their points. Eg:Points = points + selected points.',
        'If the user loses the match, the bidding amount will be deducted from their points. Eg:Points = points - selected points.',
        'If a user misses a match, 200 points will be deducted from their points. Eg:Points = points - 200.',
        'If all answers are correct, 2000 points will be added as a bonus.',
        'Every correct answer for the sub-question will earn 500 points each.',
      ],
    },
  ];

  const renderTerms = termsData => (
    <List sx={{ pl: 8, listStyle: 'numeric' }}>
      {termsData.map((term, index) => (
        <ListItem key={index} sx={{ pt: 0.2, pb: 0, display: 'list-item' }}>
          <ListItemText primary={term} />
        </ListItem>
      ))}
    </List>
  );

  const renderRules = rules => (
    <List sx={{ pl: 8, listStyle: 'numeric' }}>
      {rules.map((rule, index) => (
        <ListItem key={index} sx={{ pt: 0.2, pb: 0, display: 'list-item' }}>
          <ListItemText primary={rule.content} sx={{ pl: 0 }} />
          {rule.subContent && renderSubContent(rule.subContent)}
        </ListItem>
      ))}
    </List>
  );

  const renderSubContent = subContent => (
    <List sx={{ pl: 6, pt: 0, pb: 0, listStyle: 'lower-alpha' }}>
      {subContent.map((subItem, subIndex) => (
        <ListItem key={subIndex} sx={{ display: 'list-item', pt: 0.2, pb: 0 }}>
          <ListItemText primary={subItem} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Container maxWidth="md">
        <Typography variant="h5" fontWeight={'700'} gutterBottom>
          Tournament Rules
        </Typography>
        <Typography
          variant="h6"
          fontWeight={'550'}
          textAlign={'start'}
          pl={1}
          fontSize={'1.1rem'}
        >
          Terms:
        </Typography>
        {renderTerms(terms)}
        <Typography
          variant="h6"
          fontWeight={'550'}
          textAlign={'start'}
          pl={1}
          fontSize={'1.1rem'}
        >
          Rules:
        </Typography>
        {renderRules(rules)}
      </Container>
    </div>
  );
};

export default RulesPage;
