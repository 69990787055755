const TournamentActionType = {
    GET_TOURNAMENTS: "GET_TOURNAMENTS",
    SET_TOURNAMENTS: "SET_TOURNAMENTS",
    FETCH_ALL_TOURNAMENTS: "FETCH_ALL_TOURNAMENTS",
    SET_CURRENT_TOURNAMENTS: "SET_CURRENT_TOURNAMENTS",
    SET_ACTIVE_TOURNAMENTS: "SET_ACTIVE_TOURNAMENTS",
    SET_ONGOING_TOURNAMENTS: "SET_ONGOING_TOURNAMENTS",
    ADD_TOURNAMENTS_TO_USER: "ADD_TOURNAMENTS_TO_USER",
    ADD_TOURNAMENTS_TO_GROUP: "ADD_TOURNAMENTS_TO_GROUP",
    GET_TOP_USER: "GET_TOP_USER",
    SET_TOP_USER: "SET_TOP_USER",
    GET_TOP_GROUPS: "GET_TOP_GROUPS",
    SET_TOP_GROUPS: "SET_TOP_GROUPS",
    GET_TOP_PREDICTORS: "GET_TOP_PREDICTORS",
    SET_TOP_PREDICTORS: "SET_TOP_PREDICTORS",
    GET_USER_GROUPS: "GET_USER_GROUPS",
    SET_USER_GROUPS: "SET_USER_GROUPS",
    GET_USER_GROUPS_MEMBERS: "GET_USER_GROUPS_MEMBERS",
    SET_USER_GROUPS_MEMBERS: "SET_USER_GROUPS_MEMBERS",
    GET_MATCH_SCHEDULE: "GET_MATCH_SCHEDULE",
    SET_MATCH_SCHEDULE: "SET_MATCH_SCHEDULE",
    GET_TOURNAMENT_DETAILS: "GET_TOURNAMENT_DETAILS",
    SET_TOURNAMENT_DETAILS: "SET_TOURNAMENT_DETAILS",
}

export default TournamentActionType
