import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './routes/auth';
import SignUp from './routes/auth/signUp';
import Dashboard from './routes/dashboard/index';
import Groups from './routes/groups/index';
import GroupDetails from './routes/groups/groupDetails';
import TournamentDetails from './routes/dashboard/tournamentDetails';
import GroupMembersDetails from './routes/dashboard/groupMembers';
import { Container } from '@mui/material';
import Drawer from './components/nav/Drawer';
import { toast } from 'react-toastify';
import { setError } from './redux/actions/action.actions';
import MatchQuestion from './routes/matches';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './routes/profile/index';
import RulesPage from './routes/rules/index';
import FAQsPage from './routes/FAQ/index';
import { getUser } from './redux/auth/auth.actions';
import UserMatchdetails from './routes/dashboard/userMatch';
import { IsUserLoggedIn } from './utils/auth';
import ForgotPassword from './routes/auth/forgotPassword';
import ResetPassword from './routes/auth/resetPassword';
import TermsAndConditions from './components/AgreementAndLicense';
import Prizes from './routes/prize';

const theme = mode => {
  return createTheme({
    palette: {
      mode: mode ? mode : 'light',
      primary: {
        light: '#83c098', //'#62a8d7',
        main: '#65b17f', //'#3b93cd',
        dark: '#467b58', //'#29668f',
        primaryBg: '#e6f7f6',
        contrastText: '#fff',
      },

      secondary: {
        light: '#63ddac',
        main: '#3dd598',
        dark: '#2a956a',
      },
      success: {
        light: '#9bcf63',
        main: '#82c43c',
        dark: '#5b892a',
      },
      error: {
        light: '#fc7b7b',
        main: '#fc5a5a',
        dark: '#b03e3e',
      },
      warning: {
        light: '#ffab6e',
        main: '#ff974a',
        dark: '#b26933',
      },
      info: {
        light: '#73c3ff',
        main: '#50b5ff',
        dark: '#387eb2',
      },
      almostBlack: {
        0: '#ffffff',
        100: '#fafafb',
        200: '#f1f1f5',
        300: '#e2e2ea',
        400: '#d5d5dc',
        500: '#b5b5be',
        600: '#92929d',
        700: '#696974',
        800: '#44444f',
        900: '#171725',
      },
      background: {
        paper: '#fff',
        default: '#fafafb',
      },
      text: {
        primary: '#171725',
        secondary: '#92929d',
        disabled: '#808080',
        hint: '#e2e2ea',
      },
      others: {
        tableHeaderBg: {
          main: '#9e9c9c14',
        },
      },
      glassX: {
        bgColor: '#fafcff',
        laneColor: '#f2f6fa',
      },
      progress: {
        inprogress: '#ffa500',
        completed: '#65b17f',
      },
    },
  });
};

function App() {
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const terms = localStorage.getItem('terms');
  const token = localStorage.getItem('token');
  if (token) {
    const isTokenValid = IsUserLoggedIn(token);
    if (!isTokenValid) {
      // Token is expired or invalid, redirect to login
      localStorage.clear();
      window.location.href = '/login';
    }
  }
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const error = useSelector(state => state.actions.error);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: 'top-center',
        autoClose: 5000,
      });
      dispatch(setError(null));
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (!user && token) {
      dispatch(getUser());
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const terms = localStorage.getItem('terms');
    setTermsAccepted(terms === 'true');
  }, []);

  const handleAcceptTerms = () => {
    localStorage.setItem('terms', 'true');
    setTermsAccepted(true);
    navigate('/dashboard'); 
  };

  return (
    <ThemeProvider theme={theme('light')}>
      <Container
        justify="center"
        align="center"
        sx={{ padding: '0!important' }}
        maxWidth="100vw"
      >
        <ToastContainer />
        {token && <Drawer />}
        {/* Revisit this and add private routes */}
        <Routes>
          {!token ? (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
              <Route path="/" element={<Navigate to="/login" />} />
            </>
          ) : (
            <>
            {/* Uncomment the terms and condition when its needed. it will hold on temporary */}
            {/* <Route path="/terms" element={<TermsAndConditions onAcceptTerms={handleAcceptTerms} />} />
            {(termsAccepted === true || terms === 'true') ? (
              <> */}
              <Route exact path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/groups" element={<Groups />} />
              <Route path="/groups/:group_id" element={<GroupDetails />} />
              {/* <Route path="/matches" element={<Match />} /> */}
              <Route path="/matches/:match_id" element={<MatchQuestion />} />
              <Route
                path="/tournaments/:tournament_id"
                element={<TournamentDetails />}
              />
              <Route
                path="/groups/:group_id/group_members"
                element={<GroupMembersDetails />}
              />
              <Route
                path="/tournaments/:tournament_id/user/:user_id"
                element={<UserMatchdetails />}
              />
              <Route
                path="/matches/:match_id/user/:user_id"
                element={<MatchQuestion />}
              />
              <Route path="/rules" element={<RulesPage />} />

              <Route path="/FAQ" element={<FAQsPage />} />
              <Route path="/prizes" element={<Prizes />} />
            </>
            // ) : (
            //   <Route path="*" element={<Navigate to="/terms" />} />
            // )} 
            // </>
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Container>
    </ThemeProvider>
  );
}

export default App;
