export const assignRanks = (objectList) => {
    const sortedList = objectList.sort((a, b) => b.points - a.points);
  
    let rank = 1;
    let prevPoints = null;
    
    const rankedList = sortedList.map((user, index) => {
      const userPoints = user.points === null || user.points === '' ? 0 : user.points;
      if (userPoints !== prevPoints) {
        prevPoints = userPoints;
        user.rank = rank;
        rank++;
      } else {
        user.rank = rank - 1;
      }
      return user;
    });
  
    return rankedList;
  };