import { takeEvery, put } from "redux-saga/effects";
import { GET, POST } from "../../axios";
import QuestionActionType from "./question.type";
import * as QuestionAction from "./question.actions";
import { setError } from "../actions/action.actions";
import urls from "../urls";

function* fetchQuestionsSaga(sport) {
    try {
        const data = yield GET(
            `${urls.questions}?sport=${encodeURIComponent(sport.sport_id)}`
        );
        if (data && data.data) {
            yield put(QuestionAction.setQuestions(data.data));
        } else {
            let errorMessage =
                "An error occurred while fetching matches. Please try again later.";
            if (data.detail) {
                errorMessage = data.detail;
            }
            yield put(setError(errorMessage));
        }
    } catch (error) {
        console.error("Error during tournament matches:", error);
        yield put(setError("An unexpected error occurred."));
    }
}

function* fetchMainOPtionsSaga(match) {
    try {
        const data = yield GET(
            `${urls.matches}${match.match_id}/main_options/`
        );
        if (data && data.data) {
            yield put(QuestionAction.setMainOptions(data.data));
        } else {
            let errorMessage =
                "An error occurred while fetching matches. Please try again later.";
            if (data.detail) {
                errorMessage = data.detail;
            }
            yield put(setError(errorMessage));
        }
    } catch (error) {
        console.error("Error during tournament matches:", error);
        yield put(setError("An unexpected error occurred."));
    }
}

function* fetchPlayersSaga(match) {
    try {
        const data = yield GET(`${urls.matches}${match.match_id}/players/`);
        if (data && data.data) {
            yield put(QuestionAction.setPlayers(data.data));
        } else {
            let errorMessage =
                "An error occurred while fetching matches. Please try again later.";
            if (data.detail) {
                errorMessage = data.detail;
            }
            yield put(setError(errorMessage));
        }
    } catch (error) {
        console.error("Error during tournament matches:", error);
        yield put(setError("An unexpected error occurred."));
    }
}

function* fetchAnswersSaga({matchId, callback, userId}) {
    try {
        let url = `${urls.matches}${matchId}/answers/`;
        if (userId) url += `?user=${userId}`

        const data = yield GET(url);

        if (data?.data) {
          callback(data.data);
        } else {
            let errorMessage =
                "An error occurred while fetching matches. Please try again later.";
            if (data.detail) {
                errorMessage = data.detail;
            }
            yield put(setError(errorMessage));
        }
    } catch (error) {
        console.error("Error during tournament matches:", error);
        yield put(setError("An unexpected error occurred."));
    }
}

function* addAnswersSaga({ answerObj, callback }) {
    try {
        const { question_id, match_id, selected_points, answer } = answerObj;
        const data = yield POST(`${urls.questions}${question_id}/answer/`, {
            match: match_id,
            selected_points: selected_points || "",
            answer: answer || "",
        });
        if (data?.success === true) {
            callback();
        } else {
            let errorMessage =
                "An error occurred while adding answers. Please try again later.";
            if (data.message) {
                errorMessage = data.message;
            }
            yield put(setError(errorMessage));
        }
    } catch (error) {
        console.error("Error while adding answers:", error);
        yield put(setError("An unexpected error occurred."));
    }
}

export function* questionSagas() {
    yield takeEvery(QuestionActionType.GET_QUESTION, fetchQuestionsSaga);
    yield takeEvery(QuestionActionType.GET_MAIN_OPTIONS, fetchMainOPtionsSaga);
    yield takeEvery(QuestionActionType.GET_PLAYERS, fetchPlayersSaga);
    yield takeEvery(QuestionActionType.GET_ANSWERS, fetchAnswersSaga);
    yield takeEvery(QuestionActionType.ADD_ANSWERS, addAnswersSaga);
}
