import React, { useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment,
  TextField,
  Badge,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Container from '../../sharedComponents/Container';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import tournamentJoinScreenshot from '../../assets/FAQs/joinTournament.png';
import tournamentJoinScreenshot1 from '../../assets/FAQs/joinTournament1.png';
import playMatchScreenshot from '../../assets/FAQs/playMatch.png';
import matchPointsScreenshot from '../../assets/FAQs/matchPoint.png';
import breakdownPointsScreenshot from '../../assets/FAQs/breakdownPoints.png';
import userRankingScreenshot from '../../assets/FAQs/userRanking.png';
import createGroupScreenshot from '../../assets/FAQs/createGroup.png';
import createGroupScreenshot1 from '../../assets/FAQs/createGroup1.png';
import addGroupMembersScreenshot from '../../assets/FAQs/addGroupMembers.png';
import addGroupMembersScreenshot1 from '../../assets/FAQs/addGroupMembers1.png';
import addGroupMembersScreenshot2 from '../../assets/FAQs/addGroupMembers2.png';
import groupTournamentScreenshot from '../../assets/FAQs/groupTournament.png';
import groupRankingScreenshot from '../../assets/FAQs/groupRanking.png';
import myGroupPointsScreenshot from '../../assets/FAQs/myGroupPoints.png';
import membersPointScreenshot from '../../assets/FAQs/membersPoints.png';

const FAQsPage = () => {
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const faqs = [
    {
      question: 'How to join a tournament?',
      answer:
        "To join a tournament, navigate to the tournaments section on the platform, In the homepage, click on 'Join' for the desired tournament, Alternatively, you can access tournaments by clicking on the menu icon (displayed as ≡) on the top left,Then, select the tournament and click on 'Join' to participate.",
      screenshot: [tournamentJoinScreenshot, tournamentJoinScreenshot1],
    },
    {
      question: 'How to play a match?',
      answer:
        'After joining a tournament, locate the scheduled match in your tournament dashboard...',
      screenshot: [playMatchScreenshot],
    },
    {
      question: 'How to check the points of a match?',
      answer:
        'To check the points of a match, go to your tournament dashboard...',
      screenshot: [matchPointsScreenshot],
    },
    {
      question: 'How to check the breakdown of the points in a match?',
      answer:
        "To view the breakdown of points in a match, go to the tournament section of the platform and click on 'Schedule'. In the points column, you will see the points for respective matches. Click on 'Points' to check the breakdown of the points.",
      screenshot: [breakdownPointsScreenshot],
    },
    {
      question: 'How to check the user ranking?',
      answer:
        "To check the user ranking, go to the tournament section of the platform and click on 'top users'",
      screenshot: [userRankingScreenshot],
    },
    {
      question: 'How to create a group?',
      answer:
        "To create a group, go to the 'Manage Groups' section of the platform, click on the 'plus' icon at the bottom right, add a group name, and then click on 'Submit'.",
      screenshot: [createGroupScreenshot1, createGroupScreenshot],
    },
    {
      question: 'How to add members to a group?',
      answer:
        "After creating a group, click on view then click on 'menu' icon (displayed as ≡), then click on Group members, then you can add members to your group",
      screenshot: [
        addGroupMembersScreenshot1,
        addGroupMembersScreenshot2,
        addGroupMembersScreenshot,
      ],
    },
    {
      question: 'How to join a tournament as a group?',
      answer:
        "To join a tournament as a group, go to 'Manage Groups', click on 'View', then click on the 'Menu' icon (displayed as ≡), open 'Active Tournament', and join the respective tournaments.",
      screenshot: [groupTournamentScreenshot],
    },
    {
      question: 'How to check the group ranking?',
      answer:
        "To check the group ranking, click on respective Tournament click on 'Top Groups'",
      screenshot: [groupRankingScreenshot],
    },
    {
      question: 'How to check the points of my groups?',
      answer:
        "To check the points of your groups, go to dashboard click on respective Tournament click on 'My Groups'",
      screenshot: [myGroupPointsScreenshot],
    },
    {
      question: 'How to check the points of my group members?',
      answer:
        'To check the points of your group members, go to the dashboard, click on the respective "Tournament", click on "My Groups", then click on "View" on any of your groups to see the group members points.',
      screenshot: [membersPointScreenshot],
    },
  ];

  const handleAccordionChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
  };

  const filteredFaqs = faqs.filter(faq =>
    faq.question.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const renderFAQs = () => (
    <List sx={{ pt: 3 }}>
      {filteredFaqs.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleAccordionChange(`panel${index}`)}
        >
          <AccordionSummary
            expandIcon={
              <div
                style={{
                  backgroundColor: 'black',
                  borderRadius: '50%',
                  width: '36px',
                  height: '36px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ExpandMoreIcon style={{ color: 'white' }} />
              </div>
            }
            aria-controls={`faq${index}-content`}
            id={`faq${index}-header`}
            sx={{
              '& .MuiTypography-root': {
                borderBottom:
                  expanded === `panel${index}` ? '1px solid black' : 'none',
                paddingBottom: expanded === `panel${index}` ? '10px' : '0',
              },
            }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="h6" fontWeight="bold">
                    {faq.question}
                  </Typography>
                }
              />
            </ListItem>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <ListItem>
              <ListItemText primary={faq.answer} />
            </ListItem>
            {faq.screenshot && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {faq.screenshot.map((screenshot, screenshotIndex) => (
                  <div
                    key={screenshotIndex}
                    style={{ margin: '0 5px 10px 0', position: 'relative' }}
                  >
                    <Badge badgeContent={screenshotIndex + 1} color="error">
                      <img
                        src={screenshot}
                        alt={`Screenshot for ${faq.question}`}
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                        }}
                      />
                    </Badge>
                  </div>
                ))}
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </List>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Container maxWidth="md">
        <Typography
          variant="h5"
          fontWeight="bold"
          fontSize="2rem"
          paddingTop={3}
          gutterBottom
        >
          Frequently Asked Questions
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            id="search"
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {renderFAQs()}
      </Container>
    </div>
  );
};

export default FAQsPage;
