import axios from 'axios';
const { REACT_APP_API_URL } = process.env;

const headers = {};

const _axios = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 30000,
});

const source = axios.CancelToken.source();

const get_headers = () => {
  const h = {};
  Object.keys(headers).forEach(k => {
    h[k] = headers[k];
  });
  const token = localStorage.getItem('token');
  if (token) h['Authorization'] = `Bearer ${token}`;
  return h;
};

export const POST = async (url, data = null) => {
  let response = {};
  await _axios
    .post(url, data, {
      headers: get_headers(),
    })
    .then(({ data }) => {
      response = data;
    })
    .catch(e => {
      if (e.response.status === 401) {
        localStorage.clear();
        return window.location.replace('/login?sessionExpired=true');
      } else {
        response = e.response.data;
      }
    });
  return response;
};

export const GET = async (url, params = {}) => {
  let response = {};
  await _axios
    .get(url, {
      params,
      cancelToken: source.token,
      headers: get_headers(),
    })
    .then(({ data }) => {
      response = data;
    })
    .catch(e => {
      if (e.response.status === 401) {
        source.cancel("401");
        localStorage.clear();
        return window.location.replace('/login?sessionExpired=true');
      } else {
        response = e.response.data;
      }
    });
  return response;
};

export const PATCH = async (url, data = null) => {
  let response = {};
  await _axios
    .patch(url, data, {
      headers: get_headers(),
    })
    .then(({ data }) => {
      response = data;
    })
    .catch(e => {
      if (e.response.status === 401) {
        localStorage.clear();
        return window.location.replace('/login?sessionExpired=true');
      } else {
        response = e.response.data;
      }
    });
  return response;
};

export const PUT = async (url, data = null) => {
  let response = {};
  await _axios
    .put(url, data, {
      headers: get_headers(),
    })
    .then(({ data }) => {
      response = data;
    })
    .catch(e => {
      response = e.response.data;
      if (e.response.status === 401) {
        localStorage.clear();
        window.location.replace('/login');
      }
    });
  return response;
};
