import React, { useEffect, useState } from "react";
import {
  Typography,
  Avatar,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import Container from "../../sharedComponents/Container";
import { Box } from "@mui/system";
import { getUserDetails, updatePassword, updateProfile } from "../../redux/auth/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Profile = () => {
  const dispatch = useDispatch();
  // const [email, setEmail] = useState("");
  // const [currentPassword, setCurrentPassword] = useState("");
  // const [passwordError, setPasswordError] = useState("");
  const user = useSelector((state) => state.auth.userDetails);  
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [users, setUsers] = useState({
    first_name: '',
    last_name: '',
  });

  useEffect(() => {
    if (user) {
      setUsers({
        first_name: user.first_name || '',
        last_name: user.last_name || '',
      });
    } else {
      // Reset users state if user is null or undefined
      setUsers({
        first_name: '',
        last_name: '',
      });
    }
  }, [user]);

  
  const setFirstName = (e) => {
    setUsers(prevState => ({
      ...prevState,
      first_name: e.target.value
    }));
    setShowButton(true); // Set changesMade to true whenever input changes
  };

  const setLastName = (e) => {
    setUsers(prevState => ({
      ...prevState,
      last_name: e.target.value
    }));
    setShowButton(true); // Set changesMade to true whenever input changes
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 6,
    },
    avatar: {
      
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: 4,
    },
    submit: {
      margin: "30px, 0, 20px",
    },
  };

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch] )


  const handleChangePassword = () => {
    if (showPasswordFields) {
    if (!newPassword) {
      toast.warning("Please provide Password", {
        position: "top-center",
        autoClose: 3000,
      });
      return ;
    }
    if (newPassword !== confirmPassword) {
      toast.warning("Passwords don't match!", {
        position: "top-center",
        autoClose: 3000,
      });
    } else {
      dispatch(updatePassword(newPassword, confirmPassword))
      setShowPasswordFields(false);
      setNewPassword('');
      setConfirmPassword('');
    }
  } else {
    setShowPasswordFields(true);
  }
}; 
  const handleSaveProfile = () => {

    if (!users.first_name || !users.last_name) {
      toast.warning("First name and Last name are required", {
        position: "top-center",
        autoClose: 3000,
      });
      return ;
    }
    setShowButton(false);
    dispatch(updateProfile(users));
  };

  return (
    <Container>
      <Box component="main" maxWidth="sm" mt={3}>
        <div className={styles.container}>
          <Avatar 
            sx={{
              margin: 1,
              backgroundColor: (theme) => theme.palette.secondary.dark,
            }}
          >
          </Avatar>
          <Typography component="h1" variant="h5" fontWeight={'700'}>
            Basic Info
          </Typography>
          <form noValidate>
            <Grid container spacing={4} mt={6}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                required
                id="firstName"
                label="First Name"
                value={users.first_name}
                onChange={(e) => setFirstName(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="lastName"
                required
                label="Last Name"
                value={users.last_name}
                onChange={(e) => setLastName(e)}
              />
            </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  required
                  label="Email Address"
                  name="email"
                  disabled={true}
                  focused
                  autoComplete="email"
                  value={user?.email}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  // onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="phoneNumber"
                  label="Phone Number"
                  focused
                  name="phoneNumber"
                  autoComplete="phoneNumber"
                  value={phoneNumber}
                  defaultValue="+91 "
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid> */}
              <Grid item xs={12}>
                {/* <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup row aria-label="gender" name="gender1">
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                </RadioGroup>
              </FormControl> */}
              </Grid>
            </Grid>
            <Grid >
              {showPasswordFields && (
                <>
                  <TextField
                    label="New Password"
                    type="password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <TextField
                    sx={{ mt:2}}
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </>
              )}
            </Grid>
            <Grid   mt={3}>
              {!showButton && (
              <Button
                type="button"
                width={5}
                size="small"
                variant="contained"
                sx={{ bgcolor: '#33623c'}}
                className={styles.submit}
                onClick={handleChangePassword}
              >
                {showPasswordFields ? "Save Password" : "Change Password"}
              </Button>
              )}
              {showButton && (
              <Button
                type="button"
                width={5}
                size="small"
                variant="contained"
                color="primary"
                className={styles.submit}
                onClick={handleSaveProfile}
              >
                UPDATE PROFILE
              </Button>
              )}
            </Grid>
          </form>
        </div>
      </Box>
    </Container>
  );
};

export default Profile;
