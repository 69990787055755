import
    {Button,
Card,
    CardMedia,
    Typography, 
    useMediaQuery}
from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';
import { formatDate, formatTime } from '../../utils/dates';
import vsimg from '../../assets/matches/vsimg.png';
import LSGoutline from '../../assets/matches/LSGoutline.png';
import CSKoutline from '../../assets/matches/CSKoutline.png';
import DCoutline from '../../assets/matches/DCoutline.png';
import GToutline from '../../assets/matches/GToutline.png';
import KKRoutline from '../../assets/matches/KKRoutline.png';
import MIoutline from '../../assets/matches/MIoutline.png';
import PBKSoutline from '../../assets/matches/PBKSoutline.png';
import RCBoutline from '../../assets/matches/RCBoutline.png';
import RRoutline from '../../assets/matches/RRoutline.png';
import SRHoutline from '../../assets/matches/SRHoutline.png';
import f1_logo from '../../assets/banners/f1_logo.jpg';
import defaultLogo from '../../assets/matches/default-logo.png';


const MatchCard = ({match}) => {

    // const [play, setPlay] = useState(false);
    const navigate = useNavigate();
    const assets = () => [
        { name: "CSK" , image: CSKoutline },
        { name: "RCB" , image: RCBoutline },
        { name: "LSG" , image: LSGoutline },
        { name: "DC" , image: DCoutline },
        { name: "GT" , image: GToutline },
        { name: "KKR" , image: KKRoutline },
        { name: "MI" , image: MIoutline },
        { name: "PBKS" , image: PBKSoutline },
        { name: "RR" , image: RRoutline },
        { name: "SRH" , image: SRHoutline },
    ];

    const getTeamImage = (teamName) => {
        const team = assets().find((asset) => asset.name === teamName);
        return team ? team.image : defaultLogo;
    };

    const handleClick = (match) => {
        localStorage.setItem("sport_id", match.sport_id)
        navigate(`/matches/${match.id}`);
    };

    const team1Image = getTeamImage(match.match_details.short_name_team1);
    const team2Image = getTeamImage(match.match_details.short_name_team2);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isLaptop = useMediaQuery('(min-width:600px)');

    const style = {
        media: {
            height:'auto',
            width:'90px',
            marginBottom:'20px',
        },
        card: { 
            width: isMobile ? 310 : 345,
            boxShadow: '0 0 10px #ccc',
            margin:'5px 10px'
        },
        h6: {
            fontSize: '0.875rem',
            fontFamily:'"Roboto","Helvetica","Arial",sans-serif',
            fontWeight:'500',
            color:'#f6f6f6',
        },
        h4: {
            fontSize: '1.2rem',
            fontFamily:'fantasy',
            fontWeight:'550',
            marginBottom:'10px',
            color:'black'
        },
        container: {
            gap:'20px',
            display:'flex',
            flexWrap:'wrap',
            marginTop:'10px',
            justifyContent:'center',
            padding: isLaptop ? 0 : 0,
            width: 'auto',
        },
        grid: {
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            height:'140px',
            padding:'0px 10px 0 10px',
            backgroundColor:'rgb(57 57 57 / 5%)'
        },
        grid2: {
            backgroundColor: '#000000c9',
            height: '80px',
            width:'100%',
            padding: '12px 15px 0 15px',
            color: 'black',
            display: 'block',
            textAlign:'left'
        }
    };

    return (

        <>
            <Grid sx={style.container}>

                <Card style={style.card} >
                    <Grid sx={{ backgroundColor:'rgb(57 57 57 / 5%)', textAlign: 'end', pr:'10px' }}>
                    <Typography variant='h7' component={'h7'} sx={{ fontSize: '12px', fontWeight:900 }}>
                        {match.tournament}
                    </Typography>
                    </Grid>
                {match.match_details.match_type_name === "TeamMatch" ? (
                    <Grid style={style.grid}>
                         
                         <Grid>
                        <CardMedia
                            component="img"
                            image={(match.match_details.team1_logo !== "nan" && match.match_details.team1_logo) ? match.match_details.team1_logo : team1Image}
                            alt={match.match_details.team1}
                            style={style.media}
                        />
                        <Typography style={style.h4} component='h5' variant='h5'>
                            {match.match_details.short_name_team1}
                        </Typography>
                        </Grid>
                        <Grid>
                            <CardMedia component='img' image={vsimg} alt='vs_image' style={{ height:'50px', width:'60px'}} />
                        </Grid>

                        <Grid>
                        <CardMedia
                            component="img"
                            image={(match.match_details.team2_logo !== "nan" && match.match_details.team2_logo) ? match.match_details.team2_logo : team2Image}
                            alt={match.match_details.team2}
                            style={style.media}
                        />
                        <Typography style={style.h4} component='h5' variant='h5'>
                            {match.match_details.short_name_team2}
                        </Typography>
                        </Grid>
                    </Grid>
                    ) : (
                        <Grid>
                            <CardMedia component='img' image={f1_logo} alt='vs_image' style={{height:200, width:345}} />
                        </Grid>

                    )}
                    <Grid component={'div'} 
                        style={style.grid2}>
                        <Grid sx={{display:'flex', justifyContent:'space-between'}}>
                            <Typography style={style.h6} component='h4' variant='h6'>
                                Schedule : {formatDate(match.start_date, match.start_time)} | {formatTime(match.start_date, match.start_time)}
                            </Typography>
                        </Grid>
                        <Grid sx={{display:'flex', justifyContent:'space-between'}}>
                        <Typography style={style.h6} component='h4' variant='h6'>
                            {match.venue}
                        </Typography>
                        {match?.is_time_out === true ? ( 
                            <Button size="sm" color="primary"
                            sx={{
                                backgroundColor: match?.is_user_played === true ? '#dabe2cfc' : '#FF6347',
                                border:' 0.5px solid',
                                height:'25px',
                                marginTop:'5px',
                                fontWeight:'bold',
                                marginLeft: '5px',
                                color:'white',
                            }}
                            onClick={() => handleClick(match)}
                        >
                            {match?.is_user_played === true ?  "Locked" : "Missed"}
                        </Button>
                        ) : ( 
                        <Button size="sm" color="primary"
                            sx={{
                                backgroundColor: match?.is_user_played === true ? '#65b17f' : '#1E90FF',
                                border:' 0.5px solid',
                                height:'25px',
                                marginTop:'5px',
                                fontWeight:'bold',
                                color:'white',
                            }}
                            onClick={() => handleClick(match)}
                        >
                            {match?.is_user_played === true ? "Played" : "Play"}
                        </Button>
                        )}
                        </Grid>
                    </Grid>
                </Card>
         
            </Grid>
        </>
    )
}

export default MatchCard;

