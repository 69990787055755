import { takeEvery, put } from "redux-saga/effects";
import { GET } from "../../axios";
import MatchActionType from "./match.types";
import * as MatchActions from "./match.actions";
import { setError } from "../actions/action.actions";
import urls from "../urls";

function* fetchMatchSaga() {
  try {
    const data = yield GET(`${urls.matches}`);

    if (data && data.data) {
      yield put(MatchActions.setMatches(data.data));
    } else {
      let errorMessage = "An error occurred while fetching matches. Please try again later.";
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error("Error during tournament matches:", error);
    yield put(setError("An unexpected error occurred."));
  }
}



function* fetchUserMatchesSaga() {
  try {
    const user_id = localStorage.getItem('user_id');
    const data = yield GET(`users/${user_id}/matches/`);

    if (data && data.data) {
      yield put(MatchActions.setUserMatches(data.data));
    } else {
      let errorMessage = "An error occurred while fetching group list. Please try again later.";
      if (data.detail) {
        errorMessage = data.detail;
      }
      yield put(setError(errorMessage));
    }
  } catch (error) {
    console.error("Error during group list fetching:", error);
    yield put(setError("An unexpected error occurred."));
  }
}

function* fetchMatchPointsSaga({matchId, callback, userId}) {
  let url = `matches/${matchId}/points`;
  if (userId) url += `?user=${userId}`
  const data = yield GET(url);
  if (data?.data) {
    callback(data?.data);
  }
}

function* fetchMatchDetailsSaga({matchId, callback}) {
  const {data} = yield GET(`matches/${matchId}`);
  if (data) {
    callback(data);
  }
}


export function* matchSagas() {
  yield takeEvery(MatchActionType.GET_MATCHES, fetchMatchSaga);
  yield takeEvery(MatchActionType.GET_USER_MATCHES, fetchUserMatchesSaga);
  yield takeEvery(MatchActionType.GET_MATCH_POINTS, fetchMatchPointsSaga);
  yield takeEvery(MatchActionType.GET_MATCH_DETAILS, fetchMatchDetailsSaga);
}
