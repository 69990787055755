import React, { useEffect } from "react";
import Container from "../../sharedComponents/Container";
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import { Grid, Breadcrumbs, Link, Tab, Button, TableBody, Typography, Tooltip } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../utils/tables";
// import { tableCellClasses } from "@mui/material/TableCell";
// import TableCell from '@mui/material/TableCell';
// import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getMatchSchedules, getTournamentDetails } from "../../redux/tournaments/tournament.actions";
import { formatDate, formatTime } from "../../utils/dates";
import { useNavigate } from "react-router-dom";


const UserMatchdetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tournamentName = localStorage.getItem("tournament_name")
    const tournament_id = localStorage.getItem("tournament_id")
    let userId = localStorage.getItem("userId")
    const UserFname = localStorage.getItem("user_fname")
    const UserLname = localStorage.getItem("user_lname")
    const t_Details = useSelector((state) => state.tournaments.tournamentDetails);
    const matchSchedules = useSelector((state) => state.tournaments.matchSchedules);
    
    const coins = "Unit to spend for playing matches.";
    const points = "Unit indicating your earnings by playing the matches. The rankings will be decided based on the points.";

    useEffect(() => {
        dispatch(getTournamentDetails(tournament_id, userId));
        dispatch(getMatchSchedules(tournament_id, userId));
    }, [dispatch, tournament_id, userId])

    const handleClick = (match) => {
        localStorage.setItem("sport_id", match.sport_id)
        let userId = localStorage.getItem("userId")
        navigate(`/matches/${match.id}/user/${userId}`);
    };
    return (
        <>
        <Container>
        <Grid item xs={12} mt={2}>
                <Breadcrumbs
                    separator="›"
                    aria-label="breadcrumb"
                    sx={{
                        color: "black",
                        fontSize: "larger",
                        ml: { md: "60px", sm: "40px", xs: "10px" },
                    }}
                >
                    <Link
                        underline="hover"
                        key="1"
                        color="inherit"
                        href="/dashboard"
                        fontSize={"bolder"}
                    >
                        {"› "}Dashboard
                    </Link>
                    <Link
                        fontSize={"bolder"}
                        underline="hover"
                        key="1"
                        color="inherit"
                        href={`/tournaments/${tournament_id}`}
                    >
                        {tournamentName}
                    </Link>
                    <Link
                        fontSize={"bolder"}
                        underline="hover"
                        key="1"
                        color="inherit"
                    >
                        {UserFname}  {UserLname}
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Tab
                label="Matches list"
            />
            <Grid sx={{ maxWidth: "780px" , minWidth: "300px", marginTop: "20px", display: "flex", justifyContent: "space-between"  }}>
                <Typography  variant="h7" fontWeight={600}>
                    {UserFname}'s coins: {t_Details?.user_details.user_coins}
                    <Tooltip title={coins} arrow placement="top-start" sx={{ verticalAlign: 'text-top', marginLeft: '5px', color:'#3ca335' }}>
                        <InfoIcon fontSize="1.2rem" color='primary' />
                    </Tooltip>
                </Typography>
                <Typography  variant="h7" fontWeight={600}>
                    {UserFname}'s points: {t_Details?.user_details.user_points}
                    <Tooltip title={points} arrow placement="top-start" sx={{ verticalAlign: 'text-top', marginLeft: '5px', color:'#3ca335' }}>
                        <InfoIcon fontSize="1.2rem" color='primary' />
                    </Tooltip>
                </Typography>
            </Grid>
            <TableContainer sx={{ maxWidth: "800px", minWidth: "300px", marginTop: "20px" }} component={Paper}>
                <Table sx={{ width: "100%" }}>
                    <TableHead>
                        <TableRow key={"head"}>
                            <StyledTableCell
                                sx={{
                                    padding: "10px 16px",
                                    color: (theme) => theme.palette.text.secondary,
                                }}
                                align="left"
                            >
                                Date & Time
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    padding: "10px 16px",
                                    color: (theme) => theme.palette.text.secondary,
                                }}
                                align="center"
                            >
                                Matches
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    padding: "10px 16px",
                                    color: (theme) => theme.palette.text.secondary,
                                }}
                                align="center"
                            >
                                Points
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                   
                    <TableBody>
                    {matchSchedules.toReversed().map((match, index) => ( 
                            <StyledTableRow key={index}>
                                    <StyledTableCell sx={{ padding: "10px 16px" }} align="left" scope="row">
                                        {formatDate(match?.start_date, match?.start_time)}
                                        <br />
                                        {formatTime(match?.start_date, match?.start_time)}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ padding: "10px 16px" }} align="center">
                                        {match?.match_details.team1} {" VS "} 
                                        {match?.match_details.team2}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ padding: "10px 16px" }} align="center" >
                                        {match?.match_points > 0 ? (
                                                    <Button size="sm" 
                                                        sx={{
                                                            backgroundColor: '#83c098',
                                                            // border:' 0.5px solid black',
                                                            height:'25px',
                                                            fontWeight:'700',
                                                            color:'white',
                                                            // width: '50px',
                                                            '&:hover': {
                                                                backgroundColor: '#83c098' ,
                                                            }
                                                        }}
                                                        onClick={() => handleClick(match)}
                                                    >
                                                        {match?.match_points}
                                                    </Button> ) : (
                                                    <>
                                                    <Button size="sm" 
                                                        sx={{
                                                            backgroundColor: match?.is_time_out ? (match?.is_user_played ? '#dabe2cfc' : '#FF6347' ) : (match?.is_user_played ? '#65b17f' : '#1E90FF' ) , //  #802500 -blue
                                                            border:' 0.5px solid',
                                                            height:'25px',
                                                            fontWeight:'bold',
                                                            color:'white',
                                                            width: '60px',
                                                            fontSize: "0.70rem",
                                                            '&:hover': {
                                                                backgroundColor: match?.is_time_out ? (match?.is_user_played ? '#dabe2cfc' : '#FF6347' ) : (match?.is_user_played ? '#65b17f' : '#1E90FF' ) ,
                                                            }
                                                        }}
                                                        onClick={() => (match?.is_user_played === true) && handleClick(match) }
                                                    >
                                                            {(match?.is_user_played ? "Locked" : "Missed")}
                                                        
                                                    </Button>
                                                    </>
                                                )}
                                    </StyledTableCell>
                            </StyledTableRow>
                    
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
        </>
    )
}

export default UserMatchdetails;