import UserActionType from "./user.types"

const INITIAL_STATE = {
    userList: []
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionType.SET_ALL_USER:
            return {
                ...state,
                userList: action.user
            }
        default:
            return state
    }
}

export default reducer;
