import {
  Button,
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  Box,
  CircularProgress,
} from '@mui/material';
import Container from '../../sharedComponents/Container';
import React, { useEffect, useState } from 'react';
import {
  getQuestions,
  getMainOptions,
  getPlayers,
  getAnswers,
  addAnswers,
} from '../../redux/questions/question.actions';
import { getMatchDetails, getMatchPoints } from '../../redux/matches/match.actions';
import { useDispatch, useSelector } from 'react-redux';
import Question from '../../components/MatchDisplay';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const MatchQuestion = () => {
  const { match_id: matchId } = useParams();
  const sport_id = localStorage.getItem('sport_id');
  const user_id = localStorage.getItem('user_id');
  let userId = localStorage.getItem("userId")
  const dispatch = useDispatch();
  const questions = useSelector(state => state.questions.questions);
  const options = useSelector(state => state.questions.mainOptions);
  const players = useSelector(state => state.questions.players[0]);
  const [savedAnswers, setSavedAnswers] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [points, setPoints] = useState(500);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [selectedCard, setSelectedCard] = useState(null);
  const [pointsScored, setPointsScored] = useState({});
  const [matchDetails, setMatchDetails] = useState({});
  const tournamentName = localStorage.getItem("tournament_name");
  const tournament_id = localStorage.getItem("tournament_id");
  
  useEffect(() => {
    dispatch(getMatchDetails(matchId, (d) => setMatchDetails({ ...d })));
    dispatch(getQuestions(sport_id));
    dispatch(getMainOptions(matchId));
    dispatch(getPlayers(matchId));
  }, [dispatch, matchId, sport_id, user_id]);

  useEffect(() => {
    if (savedAnswers === null) {
      dispatch(
        getAnswers(matchId, d => {
          setSavedAnswers(d);
        }, userId),
      );
    }
  }, [dispatch, matchId, savedAnswers, userId]);

  useEffect(() => {
    if (matchDetails.result)
      dispatch(getMatchPoints(matchId, d => setPointsScored({ ...d }), userId ));
  }, [dispatch, matchId, matchDetails, userId]);

  useEffect(() => {
    if (savedAnswers?.length > 0) {
      const newSelectedAnswers = {};
      let _selectedPoints = null;
      let _selectedCard = {};
      savedAnswers.forEach(sa => {
        const _points = sa.selected_points;
        newSelectedAnswers[sa.question] = {
          match_id: sa.match,
          question_id: sa.question,
          answer: sa.answer,
          selected_points: _points,
        };
        if (_points) {
          _selectedPoints = _points;
          _selectedCard = sa.answer;
        }
      });
      setSelectedAnswers(newSelectedAnswers);
      setPoints(_selectedPoints);
      setSelectedCard(_selectedCard);
      setSelectedOption(_selectedCard);
    }
  }, [savedAnswers]);

  const handleCardClick = (option, question) => {
    setSelectedCard(option);
    const newItem = {
      match_id: matchId,
      question_id: question.id,
      answer: option,
      selected_points: points,
    };
    setSelectedAnswers({
      ...selectedAnswers,
      [question.id]: newItem,
    });

    setSelectedCard(option); // only update the list if the user has finalized their selection
    setSelectedOption(option);
  };

  const handleOptionChange = (event, question) => {
    const newItem = {
      match_id: matchId,
      question_id: question.id,
      answer: event.target.value,
      selected_points: null,
    };
    setSelectedAnswers({
      ...selectedAnswers,
      [question.id]: newItem,
    });
  };

  const handlePointsChange = event => {
    const _points = event.target.value;
    const _selectedAnswers = {};
    for (const key in selectedAnswers) {
      const q = selectedAnswers[key];
      if (q.selected_points !== null) {
        q.selected_points = _points;
      }
      _selectedAnswers[key] = q;
    }
    setSelectedAnswers({
      ..._selectedAnswers,
    });
    setPoints(_points);
  };

  const showSuccess = () => {
    toast.success('Answers saved succesfully!', {
      position: 'top-center',
      autoClose: 3000,
    });
  };

  const handleSaveAnswer = question => {
    const list = [];
    let mainQuestion;
    for (const key in selectedAnswers) {
      if (selectedAnswers[key]?.selected_points) {
        mainQuestion = selectedAnswers[key];
      } else {
        list.push(selectedAnswers[key]);
      }
    }

    if (!selectedOption) {
      toast.warning('Select a team!!!', {
        position: 'top-center',
        autoClose: 5000,
      });
      return;
    }

    if (isNaN(points) || parseInt(points) < 0) {
      toast.error('Please choose valid points.', {
        position: 'top-center',
        autoClose: 5000,
      });
      return;
    }

    const cb = () => {
      list.forEach((l, i) => {
        let _cb = i === list.length - 1 ? showSuccess : () => {};
        dispatch(addAnswers(l, _cb));
      });
    };

    dispatch(addAnswers(mainQuestion, list.length ? cb : showSuccess));
  };

  return (
    <Container>
      <Grid item xs={12} mt={2}>
        <Breadcrumbs
          separator="›"
          aria-label="breadcrumb"
          sx={{
            color: 'black',
            fontSize: 'larger',
            ml: { md: '60px', sm: '40px', xs: '5px' },
          }}
        >
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href="/dashboard"
            fontSize={'bolder'}
          >
            {'› '}Dashboard
          </Link>
          {tournamentName && ( 
          <Link
            fontSize={"bolder"}
            underline="hover"
            key="1"
            color="inherit"
            href={`/tournaments/${tournament_id}`}
          >
            {tournamentName}
          </Link>
          )}
          <Link fontSize={'bolder'} underline="hover" key="1" color="inherit">
            Match
          </Link>
        </Breadcrumbs>
      </Grid>

      {questions ? (
        <>
          <Question
            questions={questions}
            options={options}
            players={players}
            setPoints={handlePointsChange}
            selectedOption={selectedOption}
            points={points}
            handleCardClick={handleCardClick}
            selectedCard={selectedCard}
            handlePointsChange={handlePointsChange}
            handleOptionChange={handleOptionChange}
            answers={selectedAnswers}
            pointsScored={pointsScored}
            disabled={matchDetails?.is_time_out}
            matchDetails={matchDetails}
          />

          {pointsScored?.total_points ? (
            <Box
              sx={{
                fontSize: 'small',
                textAlign: 'left',
                width: '500px',
                maxWidth: '100%',
              }}
            >
              Match Bonus: {pointsScored?.bonus ? pointsScored?.bonus : 0 }
              <br />
              Total Points Scored: {pointsScored.total_points ? pointsScored.total_points : 0}
            </Box>
          ) : (
            <></>
          )}

          <Button
            sx={{
              mt: 2,
              fontWeight: 600,
              width: '100px',
            }}
            variant="contained"
            disabled={matchDetails?.is_time_out}
            onClick={e => handleSaveAnswer(e, questions)}
          >
            Save
          </Button>
        </>
      ) : (
        <Typography variant="body1">
          {/* This match is not scheduled for today. Please check back later. */}
          <CircularProgress />
        </Typography>
      )}
    </Container>
  );
};

export default MatchQuestion;
