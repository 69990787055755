import MatchActionType from "./match.types"

export const getMatches = () => {
    return {
        type: MatchActionType.GET_MATCHES,
    }
}

export const setMatches = (payload) => {
    return {
        type: MatchActionType.SET_MATCHES,
        payload,
    }
}
export const setMatchDetails = (payload) => {
    return {
        type: MatchActionType.SET_MATCH_DETAILS,
        payload
    }
}

export const getUserMatches = () => {
    return {
        type: MatchActionType.GET_USER_MATCHES,
    }
}

export const setUserMatches = (payload) => {
    return {
        type: MatchActionType.SET_USER_MATCHES,
        payload
    }
}

export const getMatchDetails = (matchId, callback) => {
    return {
        type: MatchActionType.GET_MATCH_DETAILS,
        matchId, callback
    }
}

export const getMatchPoints = (matchId, callback, userId) => ({
    type: MatchActionType.GET_MATCH_POINTS,
    matchId, callback, userId
});
