import React from 'react';
import { Typography, List, ListItem, ListItemText, Grid, useMediaQuery } from '@mui/material';
import Container from '../../sharedComponents/Container';
import img1 from '../../assets/sponsers/prizimg.jpg';
import img2 from '../../assets/sponsers/prizimg1.jpg';

const Prizes = () => {
  const rules = [
    {
      content: 'Prizes',
      subContent: [
        'Free one-night stay in a 5-star hotel in the UK.',
        '10% discount for new members joining the gym or renewing your membership (in Cochin).',
      ],
    },
    {
      content: 'Eligibility',
      subContent: [
        'You should be among the first three positions in the top user ranking',
        'You should be a member of the group that becomes first in the top group ranking',
        'If you satisfy any one of the above criteria, you can claim any one of the prizes',
      ],
    },
    {
      content: 'How to Claim',
      subContent: [
        'At the end of the tournament, If you satisfy any one of the eligibility criteria, please contact us to Claim the Gift code',
        'Using this Gift code, you can contact the respective outlet to claim the prize',
        'For any queries, please contact at contact@mindreams.com',
      ],
    },
  ];

  const renderRules = rules => (
    <List sx={{ justifyContent: 'center', pl: '2px' }}>
      {rules.map((rule, index) => (
        <ListItem
          key={index}
          sx={{
            pt: 0.2,
            pb: 5,
            display: 'block',
            fontWeight: '600 !important',
          }}
        >
          <ListItemText
          primary={
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
              {rule.content}
            </Typography>
          }
          sx={{ pl: 0 }}
        />
          {rule.subContent && renderSubContent(rule.subContent)}
        </ListItem>
      ))}
    </List>
  );

  const renderSubContent = subContent => (
    <List sx={{ pl: 5, pt: 0, pb: 0, listStyle: 'numeric' }}>
      {subContent.map((subItem, subIndex) => (
        <ListItem key={subIndex} sx={{ display: 'list-item', pt: 0.2, pb: 0 }}>
          <ListItemText primary={subItem} />
        </ListItem>
      ))}
    </List>
  );
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  return (
    <div style={{ display: 'flex', textAlign: 'justify' }}>
      <Container maxWidth="md" display={'block'}>
        <Typography variant="h5" fontWeight={'700'} gutterBottom sx={{ marginLeft: 2}}>
          Tournament Prizes
        </Typography>
        <Grid
          display={'flex'}
          sx={{ height: isMobile ? '50px' : isTablet ? '75px' : '100px' , justifyContent: 'center', gap: 2 }}
        >
          <img
            style={{
              width: isMobile ? '20%' : isTablet ? '15%' : '10%' ,
            }}
            src={img2}
            alt="5-star Hotel"
          />
          <img
            style={{
              width: isMobile ? '50%' : isTablet ? '40%' : '30%' ,
            }}
            src={img1}
            alt="Yes Fitness studio"
          />
        </Grid>
        {renderRules(rules)}
      </Container>
    </div>
  );
};

export default Prizes;
