export const formatDate = (dateString, timeString = '') => {
    let utcDate = new Date(dateString);
    
    if (!timeString.trim()) {
        utcDate.setUTCHours(0);
        utcDate.setUTCMinutes(0);
    } else {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        utcDate.setUTCHours(hours);
        utcDate.setUTCMinutes(minutes);
        utcDate.setUTCSeconds(seconds);
    }

    const options = { day: '2-digit', month: 'short', year: 'numeric' };

    const formattedDate = utcDate.toLocaleDateString('en-GB', options);

    return formattedDate;
}

export const formatTime = (dateString, timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    
    const [year, month, day] = dateString.split('-').map(Number);

    const utcDate = new Date();
    utcDate.setUTCFullYear(year);
    utcDate.setUTCMonth(month - 1);
    utcDate.setUTCDate(day);
    utcDate.setUTCHours(hours);
    utcDate.setUTCMinutes(minutes);

    const localDateTime = utcDate.toLocaleString(undefined, { hour12: true, hour: 'numeric', minute: 'numeric' });
    return localDateTime.toUpperCase();
}

export const formatDateTime = (dateString, timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    
    const [year, month, day] = dateString.split('-').map(Number);

    const utcDate = new Date();
    utcDate.setUTCFullYear(year);
    utcDate.setUTCMonth(month - 1);
    utcDate.setUTCDate(day);
    utcDate.setUTCHours(hours);
    utcDate.setUTCMinutes(minutes);
    utcDate.setUTCSeconds(seconds);

    const localDateTime = utcDate.toLocaleString(undefined, { hour12: true });

    return localDateTime;
}
