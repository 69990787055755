import GroupActionType from './group.types';

export const getUserGroups = (status) => {
  return {
    type: GroupActionType.FETCH_USER_GROUPS,
    status,
  };
};

export const setUserGroups = (payload) => {
  return {
    type: GroupActionType.SET_USER_GROUPS,
    payload,
  };
};

export const fetchUserGroupMembers = (group_id) => {
  return {
    type: GroupActionType.FETCH_USER_GROUP_MEMEBERS,
    group_id,
  };
};

export const setUserGroupMembers = (payload) => {
  return {
    type: GroupActionType.SET_USER_GROUP_MEMBERS,
    payload,
  };
};

export const addGroup = (name) => {
  return {
    type: GroupActionType.ADD_GROUP,
    name,
  };
};

export const exitGroup = (group_id, user_id) => {
  return {
    type: GroupActionType.EXIT_GROUP,
    group_id,
    user_id,
  };
};

export const fetchGroupTournaments = (group_id) => {
  return {
    type: GroupActionType.FETCH_GROUP_TOURNAMENTS,
    group_id,
  };
};

export const setGroupTournaments = (payload) => {
  return {
    type: GroupActionType.SET_GROUP_TOURNAMENTS,
    payload,
  };
};

export const fetchGroup = (group_id) => {
  return {
    type: GroupActionType.FETCH_GROUPS,
    group_id,
  };
};

export const setGroup = (payload) => {
  return {
    type: GroupActionType.SET_GROUPS,
    payload,
  };
};

export const updateGroupActiveStatus = (group_id, active) => {
    return {
      type: GroupActionType.UPDATE_GROUP_ACTIVE_STATUS,
      group_id,
      active,
    };
  };
