import TournamentActionType from "./tournament.types"

export const getTournaments = (status='', group_id) => {
    return {
        type: TournamentActionType.GET_TOURNAMENTS,
        status,
        group_id
    }
}

export const setTournaments = (payload) => {
    return {
        type: TournamentActionType.SET_TOURNAMENTS,
        payload
    }
}

export const fetchAllTournaments = () => {
    return {
        type: TournamentActionType.FETCH_ALL_TOURNAMENTS,
    }
}

export const setActiveTournaments = (payload) => {
    return {
        type: TournamentActionType.SET_ACTIVE_TOURNAMENTS,
        payload
    }
}

export const setOngoingTournaments = (payload) => {
    return {
        type: TournamentActionType.SET_ONGOING_TOURNAMENTS,
        payload
    }
}

export const addTournamentsToUser = (tournament_id) => {
    return {
        type: TournamentActionType.ADD_TOURNAMENTS_TO_USER,
        tournament_id
    }
}

export const addTournamentsToGroup = (tournament_id, group_id) => {
    return {
        type: TournamentActionType.ADD_TOURNAMENTS_TO_GROUP,
        tournament_id,
        group_id
    }
}

export const getTopUser = (tournament_id) => {
    return {
        type: TournamentActionType.GET_TOP_USER,
        tournament_id
    }
}

export const setTopUser = (payload) => {
    return {
        type: TournamentActionType.SET_TOP_USER,
        payload
    }
}

export const getTopGroups = (tournament_id) => {
    return {
        type: TournamentActionType.GET_TOP_GROUPS,
        tournament_id
    }
}

export const setTopGroups = (payload) => {
    return {
        type: TournamentActionType.SET_TOP_GROUPS,
        payload
    }
}

export const getTopPredictors = (tournament_id) => {
    return {
        type: TournamentActionType.GET_TOP_PREDICTORS,
        tournament_id
    }
}

export const setTopPredictors = (payload) => {
    return {
        type: TournamentActionType.SET_TOP_PREDICTORS,
        payload
    }
}

export const getUserGroups = (tournament_id) => {
    return {
        type: TournamentActionType.GET_USER_GROUPS,
        tournament_id
    }
}

export const setUserGroups = (payload) => {
    return {
        type: TournamentActionType.SET_USER_GROUPS,
        payload
    }
}

export const getUserGroupsMembers = (tournament_id, group_id) => {
    return {
        type: TournamentActionType.GET_USER_GROUPS_MEMBERS,
        tournament_id,
        group_id
    }
}

export const setUserGroupsMembers = (payload) => {
    return {
        type: TournamentActionType.SET_USER_GROUPS_MEMBERS,
        payload
    }
}

export const getMatchSchedules = (tournament_id, userId) => {
    return {
        type: TournamentActionType.GET_MATCH_SCHEDULE,
        tournament_id, userId,
    }
}

export const setMatchSchedules = (payload) => {
    return {
        type: TournamentActionType.SET_MATCH_SCHEDULE,
        payload,
    }
}

export const getTournamentDetails = (tournamentId, userId) => {
    return {
        type: TournamentActionType.GET_TOURNAMENT_DETAILS,
        tournamentId, userId
        
    }
}

export const setTournamentDetails = (payload) => {
    return {
        type: TournamentActionType.SET_TOURNAMENT_DETAILS,
        payload,
    }
}