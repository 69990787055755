const QuestionActionType = {
  GET_QUESTION: "GET_QUESTION",
  SET_QUESTION: "SET_QUESTION",
  GET_MAIN_OPTIONS: "GET_MAIN_OPTIONS",
  SET_MAIN_OPTIONS: "SET_MAIN_OPTIONS",
  GET_PLAYERS: "GET_PLAYERS",
  SET_PLAYERS: "SET_PLAYERS",
  ADD_ANSWERS: "ADD_ANSWERS",
  GET_ANSWERS: "GET_ANSWERS",
  SET_ANSWERS: "SET_ANSWERS",
};

export default QuestionActionType;
